<template>
	<!--search area-->
	<div class="search-input">
		<div class="search-close">
			<i class="icofont-close-circled"></i>
		</div>
		<form>
			<input type="text" name="text" placeholder="Search Heare">
			<button class="search-btn" type="submit">
				<i class="icofont-search-2"></i>
			</button>
		</form>
	</div>
	<!-- search area -->    
	<!-- Mobile Menu Start Here -->
		<div class="mobile-menu transparent-header">
			<nav class="mobile-header">
				<div class="header-logo">
					<a href="/"><img src="./assets/colien/images/logo/01.png" alt="logo"></a>
				</div>
				<div class="header-bar">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</nav>
			<nav class="mobile-menu">
				<div class="mobile-menu-area">
					<div class="mobile-menu-area-inner">
						<ul class="lab-ul">
							<li class="active">
								<a href="/">Home</a>
							</li>
							<li><a href="/pages/about-us/">About</a></li>
							<li>
								<a href="/blog/">Blog</a>
							</li>
							<li>
								<a href="/shop/">Shop</a>
							</li>
							<li><a href="/contact-us/">Contact</a></li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
		<!-- Mobile Menu Ending Here -->

		<!-- desktop menu start here -->
		<header class="header-section">
			<div class="header-top bg-theme">
				<div class="container">
					<div class="row justify-content-center align-items-center">
						<div class="col-lg-7 col-12">
							<div class="ht-left">
								<ul class="lab-ul d-flex flex-wrap">
									<li><i class="icofont-envelope"></i><span>colienbeefshop@gmail.com</span></li>
									<li><i class="icofont-phone"></i><span>+84 70 608 6777</span></li>
									<li><i class="icofont-stopwatch"></i><span>Mon - Fri 09:00 - 18:00</span></li>
								</ul>
							</div>
						</div>
						<div class="col-lg-5 col-12">
							<div class="ht-right">
								<div class="scocial-media">
                                    <a href="#" class="facebook"><i class="icofont-facebook"></i></a>
                                    <a href="#" class="twitter"><i class="icofont-twitter"></i></a>
                                    <a href="#" class="linkedin"><i class="icofont-linkedin"></i></a>
                                    <a href="#" class="vimeo"><i class="icofont-vimeo"></i></a>
                                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="header-bottom">
				<div class="header-area">
					<div class="container">
						<div class="primary-menu">
							<div class="logo py-2">
								<a href="/"><img src="./assets/colien/images/logo/01.png" alt="logo"></a>
							</div>
							<div class="main-area">
								<div class="main-menu d-flex flex-wrap align-items-center justify-content-center w-100">
									<ul class="lab-ul">
										<li class="active">
											<a href="/">Home</a>
										</li>
										<li><a href="/pages/about-us/">About</a></li>
										<li>
											<a href="/blog/">Blog</a>
										</li>
										<li>
											<a href="/shop/">Shop</a>
										</li>
										<li><a href="/contact-us/">Contact</a></li>
									</ul>
									<ul class="lab-ul search-cart">
										<li>
											<div class="cart-option">
												<i class="icofont-cart-alt"></i>
												<div class="cart-content">
													<div class="cart-item">
														<div class="cart-img">
															<a href="#"><img src="./assets/colien/images/shop/01.jpg" alt="cart"></a>
														</div>
														<div class="cart-des">
															<a href="#">Product Title Hore</a>
															<p>$20.00</p>
														</div>
														<div class="cart-btn">
															<a href="#"><i class="icofont-close-circled"></i></a>
														</div>
													</div>
													<div class="cart-item">
														<div class="cart-img">
															<a href="#"><img src="./assets/colien/images/shop/02.jpg" alt="cart"></a>
														</div>
														<div class="cart-des">
															<a href="#">Product Title Hore</a>
															<p>$20.00</p>
														</div>
														<div class="cart-btn">
															<a href="#"><i class="icofont-close-circled"></i></a>
														</div>
													</div>
													<div class="cart-bottom">
														<div class="cart-subtotal">
															<p>Total: <b class="float-right">$40.00</b></p>
														</div>
														<div class="cart-action">
															<a href="/cart/" class="lab-btn"><span>View Cart</span></a>
															<a href="/cart/checkout/" class="lab-btn"><span>Check Out</span></a>
														</div>
													</div>
												</div>
											</div>
										</li>
										<li>
											<div class="search-option">
												<i class="icofont-search-2"></i>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
        <!-- desktop menu ending here -->
	<router-view/>
	
	<!-- footer section start here -->
		<footer>
			<div class="footer-top relative padding-tb bg-ash">
				<div class="shape-images">
					<img src="./assets/colien/images/shape-images/03.png" alt="shape-images">
				</div>
				<div class="container">
					<div class="section-wrapper row">
						<div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
							<div class="post-item">
								<div class="footer-logo mb-3">
									<img src="./assets/colien/images/logo/01.png" alt="footer-logo">
								</div>
								<p>Cung cấp sỉ và lẻ thịt bò cỏ tươi và thực phẩm chế biến từ thịt bò nhà Cô Liên. Các món đặc sản của Shop trong 30 năm nay: Chả Bò, Bò Tươi Nướng Cay, Bò Ngâm Mắm và còn nhiều nữa...</p>
								<ul class="lab-ul footer-location">
									<li>
										<div class="icon-part">
											<i class="icofont-phone"></i>
										</div>
										<div class="content-part">
											<p>+84 70 608 6777</p>
										</div>
									</li>
									<li>
										<div class="icon-part">
											<i class="icofont-wall-clock"></i>
										</div>
										<div class="content-part">
											<p>Mon - Fri 09:00 - 18:00</p>
											<p>(except public holidays)</p>
										</div>
									</li>
									<li>
										<div class="icon-part">
											<i class="icofont-location-pin"></i>
										</div>
										<div class="content-part">
											<p>123 Pham Tu, Khue Trung Ward, Cam Le District, Da Nang City, Viet Nam</p>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
							<div class="post-item">
								<div class="post-title">
									<h5>Top Selling Product</h5>
								</div>
								<div class="lab-ul footer-post">
									<div class="media mb-3">
										<div class="fp-thumb mr-3">
											<img src="./assets/colien/images/products/product/01.png" alt="recent-post">
										</div>
										<div class="media-body">
											<a href="#"><h6 class="mt-0">Fresh Mutton Cuts</h6></a>
											<div class="rating">
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<span>(Review 3)</span>
											</div>
											<span class="price"><del>$45.99</del></span>
											<span class="price">$25.99</span>
										</div>
									</div>
									<div class="media mb-3">
										<div class="fp-thumb mr-3">
											<img src="./assets/colien/images/products/product/02.png" alt="recent-post">
										</div>
										<div class="media-body">
											<a href="#"><h6 class="mt-0">Fresh Mutton Cuts</h6></a>
											<div class="rating">
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<span>(Review 3)</span>
											</div>
											<span class="price"><del>$45.99</del></span>
											<span class="price">$25.99</span>
										</div>
									</div>
									<div class="media mb-3">
										<div class="fp-thumb mr-3">
											<img src="./assets/colien/images/products/product/03.png"  alt="recent-post">
										</div>
										<div class="media-body">
											<a href="#"><h6 class="mt-0">Fresh Mutton Cuts</h6></a>
											<div class="rating">
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<i class="icofont-ui-rating"></i>
												<span>(Review 3)</span>
											</div>
											<span class="price"><del>$45.99</del></span>
											<span class="price">$25.99</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
							<div class="post-item">
								<div class="post-title">
									<h5>Gallery</h5>
								</div>
								<div class="lab-ul footer-gellary">
									<figure class="figure">
										<a href="#"><img src="./assets/colien/images/gallery/01.jpg" class="img-fluid rounded" alt="footer-gellary"></a>
									</figure>
									<figure class="figure">
										<a href="#"><img src="./assets/colien/images/gallery/02.jpg" class="img-fluid rounded" alt="footer-gellary"></a>
									</figure>
									<figure class="figure">
										<a href="#"><img src="./assets/colien/images/gallery/03.jpg" class="img-fluid rounded" alt="footer-gellary"></a>
									</figure>
									<figure class="figure">
										<a href="#"><img src="./assets/colien/images/gallery/04.jpg" class="img-fluid rounded" alt="footer-gellary"></a>
									</figure>
									<figure class="figure">
										<a href="#"><img src="./assets/colien/images/gallery/05.jpg" class="img-fluid rounded" alt="footer-gellary"></a>
									</figure>
									<figure class="figure">
										<a href="#"><img src="./assets/colien/images/gallery/06.jpg" class="img-fluid rounded" alt="footer-gellary"></a>
									</figure>
									<figure class="figure">
										<a href="#"><img src="./assets/colien/images/gallery/07.jpg" class="img-fluid rounded" alt="footer-gellary"></a>
									</figure>
									<figure class="figure">
										<a href="#"><img src="./assets/colien/images/gallery/08.jpg" class="img-fluid rounded" alt="footer-gellary"></a>
									</figure>
									<figure class="figure">
										<a href="#"><img src="./assets/colien/images/gallery/09.jpg" class="img-fluid rounded" alt="footer-gellary"></a>
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-bottom wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
				<div class="container">
					<div class="section-wrapper">
						<p class="text-center">&copy; {{ new Date().getFullYear() }} Co Lien Beef Shop. Platform By <a href="https://semode.com">Semode</a></p>
					</div>
				</div>
			</div>
		</footer>
		<!-- footer section start here -->

		<!-- scrollToTop start here -->
		<a href="#" class="scrollToTop"><i class="icofont-swoosh-up"></i><span class="pluse_1"></span><span class="pluse_2"></span></a>
		<!-- scrollToTop ending here -->
</template>

<script>
import axios from 'axios'
import Toast from '@/components/Toast.vue'
export default {
  data() {
    return {
      showMobileMenu: false,
      cart: {
        items: []
      },
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')
    const token = this.$store.state.token
    if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
        axios.defaults.headers.common['Authorization'] = ""
    }
  },
  mounted() {
  	this.getItemList()
  	this.getCoinbase()
    //console.log(this.$store.state.user.username)
    this.cart = this.$store.state.cart
    },
  methods: {
  	async getItemList() {
  		await axios
                .get("/api/items/")
                .then(response => {
					//console.log(response.data.results)
					//this.$store.commit('setTrendingArtworks', response.data.results);
					//localStorage.setItem("trendingArtworks", response.data.results);
					//this.$store.state.trendingArtworks = response.data.results
					//localStorage.setItem("trendingArtworks", JSON.stringify(response.data.results));
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        
                        console.log(JSON.stringify(error))
                    }
    	})
  	},
  	async getCoinbase() {
  			await axios
                .get("https://api.coinbase.com/v2/prices/ETH-USD/spot")
                .then(response => {
					this.$store.commit('setRate', response.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        
                        console.log(JSON.stringify(error))
                    }
                })
    },
  	async logout() {
  			await axios
                .post("/api/v1/token/logout/", {"token":localStorage.getItem("token")})
                .then(response => {
                    
                    axios.defaults.headers.common["Authorization"] = ""
			
		            localStorage.removeItem("token")
		            localStorage.removeItem("username")
		            localStorage.removeItem("userid")
		
		            this.$store.commit('removeToken')
		            this.$store.state.user = ''
		            localStorage.removeItem("user")
		
		            this.$router.push('/')
		            
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        
                        console.log(JSON.stringify(error))
                    }
                })
        },
  },
  computed: {
      cartTotalLength() {
          let totalLength = 0

          for (let i = 0; i < this.cart.items.length; i++) {
              totalLength += this.cart.items[i].quantity
          }

          return totalLength
      }
  }
}
</script>
