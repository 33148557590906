<template>
	<div class="inner-banner inner-bg5">
		<div class="container">
			<div class="inner-title">
				<h3>Register</h3>
				<ul>
					<li><a href="/">Home</a></li>
					<li>Pages</li>
					<li>Register</li>
				</ul>
				<div class="inner-shape">
					<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
					<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
				</div>
			</div>
		</div>
	</div>
   	<div class="user-area pt-100 pb-70">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-12">
					<div class="user-all-form">
					<div class="contact-form">
					<h3> Create Account </h3>
					<div class="bar"></div>

                    <form @submit.prevent="submitForm">
                    	<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
							 	<label>Fullname</label>
								<input type="text" class="form-control" v-model="full_name">
							</div>
						</div>
						<div class="col-lg-12 ">
							<div class="form-group">
								<label>Email</label>
								<input type="text" class="form-control" v-model="email">
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label>Password</label>
								<input type="password" class="form-control" v-model="password">
							</div>
						</div>
						
						<div class="col-12">
							<div class="form-group">
								<label>Repeat Password</label>
								<input type="password" class="form-control" v-model="password2">
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
		                        <div class="notification is-danger" v-if="errors.length">
		                            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
		                        </div>
                        	</div>
                        </div>
						<div class="col-lg-12 col-md-12 text-center">
							<button type="submit" class="default-btn">
								Register Now
							</button>
						</div>
						<div class="col-12">
						<div class="sub-title">
						<span>Or</span>
						</div>
						</div>
						<div class="login-with-account">
							<ul>
								<li>
									<a href="/login/" target="_blank">
										Login
									</a>
								</li>
							</ul>
						</div>
                    </div>
                    </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Register',
    data() {
        return {
            full_name: '',
            email:'',
            password: '',
            password2: '',
            errors: []
        }
    },
    methods: {
        submitForm() {
            this.errors = []

            if (this.full_name === '') {
                this.errors.push('The fullname is missing')
            }

            if (this.email === '') {
                this.errors.push('The email is missing')
            }

            if (this.password === '') {
                this.errors.push('The password is too short')
            }

            if (this.password !== this.password2) {
                this.errors.push('The passwords doesn\'t match')
            }

            if (!this.errors.length) {
                const formData = {
                    full_name: this.full_name,
                    email: this.email,
                    password: this.password
                }

                axios
                    .post("/api/v1/users/", formData)
                    .then(response => {
                        this.$router.push('/login/')
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }

                            console.log(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again')
                            
                            console.log(JSON.stringify(error))
                        }
                    })
            }
        }
    }
}
</script>