<template>
<div class="inner-banner inner-bg5">
<div class="container">
<div class="inner-title">
<h3>Live Auction Is Going On</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Discover</li>
<li> Live Auction</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="auctions-area-three pt-100 pb-70">
<div class="container">
<div class="section-title">
<h2>Live Auctions</h2>
</div>
<div class="row justify-content-center pt-45">
<div class="col-lg-4 col-md-6">
<div class="auction-card">
<div class="auction-card-img">
<a href="auction.html">
<img src="../assets/dab/images/auctions/auctions-style1.jpg" alt="Images">
</a>
 <div class="auction-card-user">
<a href="author-profile.html" class="auction-card-user-option">
<img src="../assets/dab/images/auctions/auctions-user1.jpg" alt="Images">
<span>Created by @Adison</span>
</a>
</div>
<div class="auction-card-into">
<h3>Remaining Time</h3>
<div class="auction-timer">
<div class="auction-title" data-countdown="2021/10/10"></div>
</div>
</div>
</div>
<div class="content">
<h3> <a href="auction.html">To Infinity And Beyond</a></h3>
<p><i class="ri-heart-line"></i> 142</p>
<div class="auction-card-content">
<div class="card-left">
<span>Start Bid</span>
<h4>15,00 ETH</h4>
</div>
<div class="card-right">
<span>Highest Bid</span>
<h4>15,00 ETH</h4>
</div>
</div>
<a href="auction.html" class="place-btn">Place Bid</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="auction-card">
<div class="auction-card-img">
<a href="auction.html">
<img src="../assets/dab/images/auctions/auctions-style2.jpg" alt="Images">
</a>
<div class="auction-card-user">
<a href="author-profile.html" class="auction-card-user-option">
<img src="../assets/dab/images/auctions/auctions-user2.jpg" alt="Images">
<span>Created by @Emilia</span>
</a>
</div>
<div class="auction-card-into">
<h3>Remaining Time</h3>
<div class="auction-timer">
<div class="auction-title" data-countdown="2021/10/10"></div>
</div>
</div>
</div>
<div class="content">
<h3> <a href="auction.html">Walking On Air</a></h3>
<p><i class="ri-heart-line"></i> 162</p>
<div class="auction-card-content">
<div class="card-left">
<span>Start Bid</span>
<h4>16,00 ETH</h4>
</div>
<div class="card-right">
<span>Highest Bid</span>
<h4>12,00 ETH</h4>
</div>
</div>
<a href="auction.html" class="place-btn">Place Bid</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="auction-card">
<div class="auction-card-img">
<a href="auction.html">
<img src="../assets/dab/images/auctions/auctions-style3.jpg" alt="Images">
</a>
<div class="auction-card-user">
<a href="author-profile.html" class="auction-card-user-option">
<img src="../assets/dab/images/auctions/auctions-user3.jpg" alt="Images">
<span>Created by @Daniel</span>
</a>
</div>
<div class="auction-card-into">
<h3>Remaining Time</h3>
<div class="auction-timer">
<div class="auction-title" data-countdown="2021/10/10"></div>
</div>
</div>
</div>
<div class="content">
<h3> <a href="auction.html">Industrial Revolution</a></h3>
<p><i class="ri-heart-line"></i> 142</p>
<div class="auction-card-content">
<div class="card-left">
<span>Start Bid</span>
<h4>14,00 ETH</h4>
</div>
<div class="card-right">
<span>Highest Bid</span>
<h4>11,00 ETH</h4>
</div>
</div>
<a href="auction.html" class="place-btn">Place Bid</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="auction-card">
<div class="auction-card-img">
<a href="auction.html">
<img src="../assets/dab/images/auctions/auctions-style4.jpg" alt="Images">
</a>
<div class="auction-card-user">
<a href="author-profile.html" class="auction-card-user-option">
<img src="../assets/dab/images/auctions/auctions-user4.jpg" alt="Images">
<span>Created by @Jekob</span>
</a>
</div>
<div class="auction-card-into">
<h3>Remaining Time</h3>
<div class="auction-timer">
<div class="auction-title" data-countdown="2021/10/10"></div>
</div>
</div>
</div>
<div class="content">
<h3> <a href="auction.html">Become On Nature</a></h3>
<p><i class="ri-heart-line"></i> 142</p>
<div class="auction-card-content">
<div class="card-left">
<span>Start Bid</span>
<h4>17,00 ETH</h4>
</div>
<div class="card-right">
<span>Highest Bid</span>
<h4>13,00 ETH</h4>
</div>
</div>
 <a href="auction.html" class="place-btn">Place Bid</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="auction-card">
<div class="auction-card-img">
<a href="auction.html">
<img src="../assets/dab/images/auctions/auctions-style5.jpg" alt="Images">
</a>
<div class="auction-card-user">
<a href="author-profile.html" class="auction-card-user-option">
<img src="../assets/dab/images/auctions/auctions-user5.jpg" alt="Images">
<span>Created by @Emilia</span>
</a>
</div>
<div class="auction-card-into">
<h3>Remaining Time</h3>
<div class="auction-timer">
<div class="auction-title" data-countdown="2021/11/11"></div>
</div>
</div>
</div>
<div class="content">
<h3> <a href="auction.html">Flowers In Concrete</a></h3>
<p><i class="ri-heart-line"></i> 172</p>
<div class="auction-card-content">
<div class="card-left">
<span>Start Bid</span>
<h4>16,00 ETH</h4>
</div>
<div class="card-right">
<span>Highest Bid</span>
<h4>12,00 ETH</h4>
</div>
</div>
<a href="auction.html" class="place-btn">Place Bid</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="auction-card">
<div class="auction-card-img">
<a href="auction.html">
<img src="../assets/dab/images/auctions/auctions-style6.jpg" alt="Images">
</a>
<div class="auction-card-user">
<a href="author-profile.html" class="auction-card-user-option">
<img src="../assets/dab/images/auctions/auctions-user6.jpg" alt="Images">
<span>Created by @Jekob</span>
</a>
</div>
<div class="auction-card-into">
<h3>Remaining Time</h3>
<div class="auction-timer">
<div class="auction-title" data-countdown="2021/10/10"></div>
</div>
</div>
</div>
<div class="content">
<h3> <a href="auction.html">Darklight Angel</a></h3>
<p><i class="ri-heart-line"></i> 182</p>
<div class="auction-card-content">
<div class="card-left">
<span>Start Bid</span>
<h4>14,00 ETH</h4>
</div>
 <div class="card-right">
<span>Highest Bid</span>
<h4>11,00 ETH</h4>
</div>
</div>
<a href="auction.html" class="place-btn">Place Bid</a>
</div>
</div>
</div>
<div class="col-lg-12 col-md-12 text-center">
<div class="pagination-area">
<a href="auction.html" class="prev page-numbers">
<i class="ri-arrow-left-s-line"></i>
</a>
<span class="page-numbers current" aria-current="page">1</span>
<a href="auction.html" class="page-numbers">2</a>
<a href="auction.html" class="page-numbers">3</a>
<a href="auction.html" class="next page-numbers">
<i class="ri-arrow-right-s-line"></i>
</a>
</div>
</div>
</div>
</div>
</div>


<div class="involved-area-two pt-100 pb-70">
<div class="container">
<div class="section-title text-center">
<h2>Get Involved</h2>
</div>
<div class="row pt-45">
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-flight-takeoff-line"></i>
</div>
<h3>Join Our <b>Community</b></h3>
<ul class="social-link">
<li>
<a href="https://www.google.com/" target="_blank">
<i class="ri-google-fill"></i>
</a>
</li>
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
<li>
<a href="https://www.linkedin.com/" target="_blank">
<i class="ri-linkedin-fill"></i>
</a>
</li>
</ul>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-checkbox-circle-line"></i>
</div>
<h3>Become A <b>Creator</b></h3>
<a href="register.html" class="default-btn">Register</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-heart-2-line"></i>
</div>
<h3>Become A <b>Charity Partner</b></h3>
<a href="create-collection.html" class="default-btn">Express Interest</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-discuss-line"></i>
</div>
<h3>Reach Out To <b>Our Team</b></h3>
<a href="contact.html" class="default-btn">Contact Now</a>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
export default {
    name: 'LiveAuction',
    mounted() {
        document.title = 'Live Auction'
    },
}
</script>