<template>
<div class="inner-banner inner-bg6">
<div class="container">
<div class="inner-title">
<h3> Forgot Password</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Pages</li>
<li> Forgot Password</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="user-area pt-100 pb-70">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-12">
<div class="user-all-form">
<div class="contact-form">
<h3>Forget Password</h3>
<div class="bar"></div>
<form @submit.prevent="submitForm" id="submitForm">
<div class="row">
<div class="col-lg-12 ">
<div class="form-group">
 <label>Email</label>
<input type="text" name="email" id="email" class="form-control" required data-error="Please enter your Username or Email" v-model="email">
</div>
</div>
<div class="col-lg-12 col-md-12 text-center">
<button type="submit" class="default-btn">
Reset Now
</button>
</div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import axios from 'axios'

export default {
    name: 'ForgotPassword',
    data() {
        return {
            email: '',
            errors: []
        }
    },
    mounted() {
        document.title = 'Forgot Password'
    },
    methods: {
  		async submitForm() {
  			const formData = {
                email: this.email
            }
            
  			await axios
                .post("/api/v1/users/reset_password/",formData)
                .then(response => {
		            this.$router.push('/')
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        
                        console.log(JSON.stringify(error))
                    }
                })
        },
  },
}
</script>