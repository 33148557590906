<template>
		<!-- Page Header Section Start Here -->
        <section class="page-header bg_img padding-tb">
            <div class="overlay"></div>
            <div class="container">
                <div class="page-header-content-area">
                    <h4 class="ph-title">{{ post.title }}</h4>
                    <ul class="lab-ul">
                        <li><a href="/">Home</a></li>
                        <li><a class="active">{{ post.title }}</a></li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- Page Header Section Ending Here -->
		
        <!-- Blog Section Start Here -->
        <div class="blog-section blog-single padding-tb bg-ash">
            <div class="container">
                <div class="section-wrapper">
                    <div class="row justify-content-center">
                        <div class="col-lg-9 col-12">
                            <article>
                                <div class="post-item-2">
                                    <div class="post-inner">
                                        <div class="post-thumb">
                                            <img :src="post.featured_image" :alt="post.title">
                                        </div>
                                        <div class="post-content">
                                            <h4>{{post.title}}</h4>
                                            <div v-html="post.content"/>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="col-lg-3 col-md-7 col-12">
                            <aside>
                                <div class="widget widget-category">
                                    <div class="widget-header">
                                        <h5>Post Categories</h5>
                                    </div>
                                    <ul class="lab-ul widget-wrapper">
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Show all</span><span>(148)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Business</span><span>(20)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Creative</span><span>(25)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Inspiation</span><span>(30)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>News</span><span>(28)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Photography</span><span>(20)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Smart</span><span>(25)</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Blog Section ENding Here -->
</template>

<script>
import axios from 'axios'

export default {
    name: 'Post',
    components: {
    },
    data() {
        return {
            post: {}
        }
    },
    mounted() {
        this.getPost()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Post') {
                this.getPost()
            }
        }
    },
    methods: {
        async getPost() {
            const postSlug = this.$route.params.post_slug

            this.$store.commit('setIsLoading', true)

            axios
                .get(`/api/posts/${postSlug}/`)
                .then(response => {
                    this.post = response.data
                    document.title = this.post.title
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>