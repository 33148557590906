import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'

import Home from '../views/Home.vue'
import NotFound from '../views/404.vue'
import Contact from '../views/Contact.vue'
import Register from '../views/Register.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
import Search from '../views/Search.vue'
import Cart from '../views/Cart.vue'
import Success from '../views/Success.vue'
import Checkout from '../views/Checkout.vue'
import Post from '../views/Post.vue'
import Page from '../views/Page.vue'
import Product from '../views/Product.vue'
import Item from '../views/Item.vue'
import Shop from '../views/Shop.vue'
import Category from '../views/Category.vue'
import AuthorProfile from '../views/AuthorProfile.vue'
import MyProfile from '../views/MyProfile.vue'
import CreateCollection from '../views/CreateCollection.vue'
import Collection from '../views/Collection.vue'
import AuthorList from '../views/AuthorList.vue'
import AllNFTList from '../views/AllNFTList.vue'
import LiveAuction from '../views/LiveAuction.vue'
import DiscoverAsset from '../views/DiscoverAsset.vue'
import Activity from '../views/Activity.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Blog from '../views/Blog.vue'
import AddWallet from '../views/AddWallet.vue'
import MintNFT from '../views/MintNFT.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact-us/',
    name: 'Contact Us',
    component: Contact,
  },
  {
    path: '/register/',
    name: 'Register',
    component: Register
  },
  {
    path: '/login/',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/forgot-password/',
    name: 'Forgot Password',
    component: ForgotPassword
  },
  {
    path: '/my-account/',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
        requireLogin: true
    }
  },
 {
    path: '/add-wallet/',
    name: 'Add Wallet',
    component: AddWallet
  },
  {
    path: '/blog/',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/author-profile/',
    name: 'My Profile',
    component: MyProfile,
    meta: {
        requireLogin: true
    }
  },
  {
    path: '/author-profile/:uuid/',
    name: 'Author Profile',
    component: AuthorProfile,
  },
  {
    path: '/authors/',
    name: 'Author List',
    component: AuthorList
  },
  {
    path: '/activity/',
    name: 'Activity',
    component: Activity
  },
  {
    path: '/all-nfts/',
    name: 'All NFTs',
    component: AllNFTList
  },
  {
    path: '/discover-assets/',
    name: 'Discover Assets',
    component: DiscoverAsset
  },
  {
    path: '/live-auction/',
    name: 'Live Auction',
    component: LiveAuction
  },
  {
    path: '/create-collection/',
    name: 'Create Collection',
    component: CreateCollection,
    meta: {
        requireLogin: true
    }
  },
  {
    path: '/mint-nft/',
    name: 'Mint NFT',
    component: MintNFT,
    meta: {
        requireLogin: true
    }
  },
  {
    path: '/collection/',
    name: 'Collection',
    component: Collection
  }, 
  {
    path: '/search/',
    name: 'Search',
    component: Search
  },
  {
    path: '/cart/',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/cart/success/',
    name: 'Success',
    component: Success
  },
  {
    path: '/cart/checkout/',
    name: 'Checkout',
    component: Checkout,
    meta: {
        requireLogin: true
    }
  },
  {
    path: '/posts/:post_slug/',
    name: 'Post',
    component: Post
  },
  {
    path: '/pages/:page_slug/',
    name: 'Page',
    component: Page
  },
  {
    path: '/shop/',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/items/:item_slug/',
    name: 'Item',
    component: Item
  },
  {
    path: '/items/category/:category_slug/',
    name: 'Category',
    component: Category
  },
  {
    path: "/:catchAll(.*)/",
    name: "NotFound",
    component: NotFound,
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path } });
  } else {
    next()
  }
})

export default router
