<template>
  <div class="row">
  	<div class="col-lg-12">
  	
    <div v-if="currentFile" class="progress">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{ width: progress + '%' }"
      >
        {{ progress }}%
      </div>
    </div>
    <div class="form-group">
		<label>Upload file</label>
       	<input class="form-control" type="file" ref="file" @change="selectFile( $event )" />
    </div>
    <div class="form-group" v-show="showImagePreview||showVideoPreview">
		<label>Preview</label>
		<div class="preview-box">
		    <span class="previewButton-file-name">
				<img v-bind:src="imagePreview" v-show="showImagePreview" style="width:60%;"/>
				<video id="video-preview" controls v-show="showVideoPreview"/>
			</span>
		</div>
	</div>
	<div class="form-group cover_image" v-show="showCoverImage" style="margin-top:10px;">
		<label>Upload Cover</label>
	    <input class="form-control" type="file" ref="imagefile" @change="selectImage( $event )" />
	</div>
	<div class="form-group" v-show="showCoverImagePreview">
		<label>Cover Preview</label>
		<img v-bind:src="coverImagePreview"/>
	</div>
    <div class="alert alert-light" role="alert">{{ message }}</div>
    </div>
  	</div>
</template>

<script>
import UploadService from "../services/UploadFilesService";
import axios from 'axios'
export default {
  name: "upload-files",
  data() {
    return {
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
	  tokenUri:{},
      fileInfos: [],
      showImagePreview: false,
      showCoverImage:false,
      showVideoPreview: false,
	  imagePreview: '',
	  file:'',
	  coverImagePreview:'',
	  showCoverImagePreview:false,
    };
  },
  methods: {
    selectFile( event ) {
      this.selectedFiles = this.$refs.file.files;
      /*
					Set the local file variable to what the user has selected.
				*/
				this.file = event.target.files[0];

				/*
					Initialize a File Reader object
				*/
				let reader  = new FileReader();
				
				/*
					Check to see if the file is not empty.
				*/
				if( this.file ){
					/*
						Ensure the file is an image file.
					*/
					console.log(this.file.type);
					console.log(this.file.type.indexOf('video/') !== -1)
					if ( /\.(jpe?g|png|gif)$/i.test( this.file.name ) ) {
						
						/*
							Fire the readAsDataURL method which will read the file in and
							upon completion fire a 'load' event which we will listen to and
							display the image in the preview.
						*/
						reader.readAsDataURL( this.file );
						/*
							Add an event listener to the reader that when the file
							has been loaded, we flag the show preview as true and set the
							image to be what was read from the reader.
						*/
						reader.addEventListener("load", function () {
							this.showImagePreview = true;
							this.showCoverImage = false;
							this.showVideoPreview = false;
							this.showCoverImagePreview = false;
							this.imagePreview = reader.result;
						}.bind(this), false);
								
					}else if (this.file.type.indexOf('video/') !== -1) {
						let video = document.getElementById('video-preview');
						let reader = new FileReader();
		
						reader.readAsDataURL( this.file );
						reader.addEventListener('load', function(){
							this.showImagePreview = false;
							this.showVideoPreview = true;
							this.showCoverImage=true;
							video.src = reader.result;
						}.bind(this), false);
					} else {
						this.showImagePreview = false;
						this.showVideoPreview = false;
						this.showCoverImage=true;
					}
				}
    },
    selectImage( event ) {
      this.selectedFiles = this.$refs.file.files;
      /*
					Set the local file variable to what the user has selected.
				*/
				this.file = event.target.files[0];

				/*
					Initialize a File Reader object
				*/
				let reader  = new FileReader();
				
				/*
					Check to see if the file is not empty.
				*/
				if( this.file ){
					/*
						Ensure the file is an image file.
					*/
					console.log(this.file.type);
					console.log(this.file.type.indexOf('video/') !== -1)
					if ( /\.(jpe?g|png|gif)$/i.test( this.file.name ) ) {
						
						/*
							Fire the readAsDataURL method which will read the file in and
							upon completion fire a 'load' event which we will listen to and
							display the image in the preview.
						*/
						reader.readAsDataURL( this.file );
						/*
							Add an event listener to the reader that when the file
							has been loaded, we flag the show preview as true and set the
							image to be what was read from the reader.
						*/
						reader.addEventListener("load", function () {
							this.showCoverImagePreview = true;
							this.coverImagePreview = reader.result;
						}.bind(this), false);
								
					}
				}
    },
    deleteItemFile: function (itemfile_id) {
      const token = localStorage.getItem("token")
        axios.defaults.headers.common["Authorization"] = "Token " + token
	    
	    axios.delete("/api/itemfiles/"+itemfile_id+"/", {})
                .then(response => {
				    console.log(response.data);
				    this.message = "Delete the file successfully!";
				    this.$store.commit('showToast', "Delete the file successfully!");
				    return UploadService.getFiles();
                })
                .then(files => {
		          this.fileInfos = files.data.results;
		        })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
    	})
    },
    async upload() {
      
      this.progress = 0;
	  const item = JSON.parse(localStorage.getItem("item"));
	  const user = JSON.parse(localStorage.getItem("user"));
	  console.log(item);
	  console.log(user);
	  if (!item) {
	 	const itemFormData = {
			title:"undefined",
			meta_description:"undefined",
			meta_keywords:"undefined",
			content:"undefined",
			quantity:1,
			price:1,
			active:1,
			user:user.id,
			currency:1,
	    }
	    await axios
                .post("/api/items/", itemFormData)
                .then(response => {
                	console.log(response.data);
					localStorage.setItem("item", JSON.stringify(response.data));
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
    	})
	  } 
      this.currentFile = this.selectedFiles.item(0);
      UploadService.upload("/api/itemfiles/",this.currentFile, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then(response => {
          this.message = response.data.message;
          this.message = "Upload the file successfully!";
          this.$store.commit('showToast', "Upload the file successfully!");
          return UploadService.getFiles();
        })
        .then(files => {
          this.fileInfos = files.data.results;
        })
        .catch(() => {
          this.progress = 0;
          this.message = "Could not upload the file!";
          this.$store.commit('showToast', "Could not upload the file!");
          this.currentFile = undefined;
        });

      this.selectedFiles = undefined;
    }
  },
  mounted() {
  	this.tokenUri=JSON.parse(localStorage.getItem("tokenUri"));
    UploadService.getFiles().then(response => {
      this.fileInfos = response.data.results;
    });
  }
};
</script>