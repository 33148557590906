import axios from 'axios';

class UploadFilesService {
  upload(url,file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
	formData.append("image", file);
	const token = localStorage.getItem("token")
	const user = JSON.parse(localStorage.getItem("user"))
    const item = JSON.parse(localStorage.getItem("item"))
	axios.defaults.headers.common["Authorization"] = "Token " + token
	formData.append("item",item.id);
	formData.append("user", user.id);
    formData.append("active",1);
	formData.append("featured",1);
	return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }

  getFiles(url) {
	const user = JSON.parse(localStorage.getItem("user"))
    const item = JSON.parse(localStorage.getItem("item"));
	if (item) {
    	return axios.get(url+"?item_id="+item.id);
	} else {
		return axios.get(url+"?item_id=0")
	}
  }
}

export default new UploadFilesService();