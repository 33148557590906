<template>
<div class="inner-banner inner-bg8">
<div class="container">
<div class="inner-title">
<h3>Recent Activity</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Activity</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="activity-area pt-100 pb-70">
<div class="container">
<div class="section-title">
<h2>Activity</h2>
</div>
<div class="row pt-45">
<div class="col-lg-9">
<div class="row justify-content-center">
<div class="col-lg-12">
<div class="activity-card">
<div class="activity-img">
<img src="../assets/dab/images/activity/activity-img1.jpg" alt="Images">
</div>
 <div class="activity-content">
<p><i class="ri-calendar-2-line"></i> 5 June, 2021</p>
<span><i class="ri-time-line"></i> 11:49 AM</span>
</div>
<div class="activity-into">
<h3>Supper Nuemorphism</h3>
<span>Listed By <b>@Jackon</b> For <b>230 ETH</b> Each</span>
</div>
<div class="activity-btn">
<i class="ri-delete-bin-4-line"></i>
</div>
</div>
</div>
<div class="col-lg-12">
<div class="activity-card">
<div class="activity-img">
<img src="../assets/dab/images/activity/activity-img2.jpg" alt="Images">
</div>
<div class="activity-content">
<p><i class="ri-calendar-2-line"></i> 7 June, 2021</p>
<span><i class="ri-time-line"></i> 10:49 AM</span>
</div>
<div class="activity-into">
<h3>Walking On Air</h3>
<span>Listed By <b>@Henry </b> For <b>130 ETH</b> Each</span>
</div>
<div class="activity-btn">
<i class="ri-delete-bin-4-line"></i>
</div>
</div>
</div>
<div class="col-lg-12">
<div class="activity-card">
<div class="activity-img">
<img src="../assets/dab/images/activity/activity-img3.jpg" alt="Images">
</div>
<div class="activity-content">
<p><i class="ri-calendar-2-line"></i> 9 June, 2021</p>
<span><i class="ri-time-line"></i> 07:49 AM</span>
</div>
<div class="activity-into">
<h3>Exe Dream Hight</h3>
<span>Listed By <b>@Henry </b> For <b>230 ETH</b> Each</span>
</div>
<div class="activity-btn">
<i class="ri-delete-bin-4-line"></i>
</div>
</div>
</div>
<div class="col-lg-12">
<div class="activity-card">
<div class="activity-img">
<img src="../assets/dab/images/activity/activity-img4.jpg" alt="Images">
</div>
<div class="activity-content">
<p><i class="ri-calendar-2-line"></i> 11 June, 2021</p>
<span><i class="ri-time-line"></i> 08:49 AM</span>
</div>
<div class="activity-into">
<h3>Become One With Nature</h3>
<span>Listed By <b>@Martina </b> For <b>270 ETH</b> Each</span>
</div>
<div class="activity-btn">
<i class="ri-delete-bin-4-line"></i>
</div>
</div>
</div>
<div class="col-lg-12">
<div class="activity-card">
<div class="activity-img">
<img src="../assets/dab/images/activity/activity-img5.jpg" alt="Images">
</div>
<div class="activity-content">
<p><i class="ri-calendar-2-line"></i> 15 June, 2021</p>
<span><i class="ri-time-line"></i> 09:49 AM</span>
</div>
<div class="activity-into">
<h3>Les Immortals</h3>
<span>Listed By <b>@Evelyn </b> For <b>290 ETH</b> Each</span>
</div>
<div class="activity-btn">
<i class="ri-delete-bin-4-line"></i>
</div>
</div>
</div>
<div class="col-lg-12 col-md-12 text-center">
<div class="pagination-area">
<a href="activity.html" class="prev page-numbers">
<i class="ri-arrow-left-s-line"></i>
</a>
<span class="page-numbers current" aria-current="page">1</span>
<a href="activity.html" class="page-numbers">2</a>
<a href="activity.html" class="page-numbers">3</a>
<a href="activity.html" class="next page-numbers">
<i class="ri-arrow-right-s-line"></i>
</a>
</div>
</div>
</div>
</div>
<div class="col-lg-3">
<div class="side-bar-area pl-20">
<div class="side-bar-widget">
<h3 class="title">Filter <a href="activity.html">Clear All</a></h3>
<form class="search-form">
<input type="search" class="form-control" placeholder="Search keyword">
<button type="submit">
<i class="ri-search-line"></i>
</button>
</form>
</div>
<div class="side-bar-widget-categories">
<h3 class="title">Categories</h3>
<ul>
<li>
<a href="categories.html" target="_blank">
Art
</a>
</li>
<li>
<a href="categories.html" target="_blank">
Virtual Worlds
</a>
</li>
<li>
<a href="categories.html" target="_blank">
Collectibles
</a>
</li>
<li>
<a href="categories.html" target="_blank">
Music
</a>
</li>
<li>
<a href="categories.html" target="_blank">
Games
</a>
</li>
<li>
<a href="categories.html" target="_blank">
Domains
</a>
</li>
<li>
<a href="categories.html" target="_blank">
Memes
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>


<div class="trending-area trending-area-bg-two pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-8 col-md-6">
<div class="section-title">
<h2>Trending Artwork</h2>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="trending-btn text-end">
<a href="item-details.html" class="default-btn border-radius-5">Explore More</a>
</div>
</div>
</div>
<div class="trending-slider owl-carousel owl-theme pt-45">
<div class="trending-item">
<div class="trending-img">
<a href="item-details.html">
<img src="../assets/dab/images/trending/trending-img6.jpg" alt="Images">
</a>
<div class="trending-user">
<a href="author-profile.html" class="trending-user-option">
<img src="../assets/dab/images/trending/trending-user1.jpg" alt="Images">
<span>Created by @Farnil</span>
</a>
</div>
<div class="trending-title">
<span>100 ETH 12/14</span>
<h3>Bid 80 ETH</h3>
</div>
</div>
<div class="content">
<h3>
<a href="item-details.html">
Les Immortal's
</a>
</h3>
<span><i class="ri-heart-line"></i> 340</span>
</div>
</div>
<div class="trending-item">
<div class="trending-img">
<a href="item-details.html">
<img src="../assets/dab/images/trending/trending-img7.jpg" alt="Images">
</a>
<div class="trending-user">
<a href="author-profile.html" class="trending-user-option">
 <img src="../assets/dab/images/trending/trending-user2.jpg" alt="Images">
<span>Created by @Anvi</span>
</a>
</div>
<div class="trending-title">
<span>110 ETH 12/14</span>
<h3>Bid 90 ETH</h3>
</div>
</div>
<div class="content">
<h3>
<a href="item-details.html">
Industrial Revolution
</a>
</h3>
<span><i class="ri-heart-line"></i> 365</span>
</div>
</div>
<div class="trending-item">
<div class="trending-img">
<a href="item-details.html">
<img src="../assets/dab/images/trending/trending-img8.jpg" alt="Images">
</a>
<div class="trending-user">
<a href="author-profile.html" class="trending-user-option">
<img src="../assets/dab/images/trending/trending-user3.jpg" alt="Images">
<span>Created by @Anvi</span>
</a>
</div>
<div class="trending-title">
<span>120 ETH 12/14</span>
<h3>Bid 70 ETH</h3>
</div>
</div>
<div class="content">
<h3>
<a href="item-details.html">
Walking on Air
</a>
</h3>
<span><i class="ri-heart-line"></i> 190</span>
</div>
</div>
<div class="trending-item">
<div class="trending-img">
<a href="item-details.html">
<img src="../assets/dab/images/trending/trending-img4.jpg" alt="Images">
</a>
<div class="trending-user">
<a href="author-profile.html" class="trending-user-option">
<img src="../assets/dab/images/trending/trending-user4.jpg" alt="Images">
<span>Created by @Aiden</span>
</a>
</div>
<div class="trending-title">
<span>100 ETH 12/14</span>
<h3>Bid 80 ETH</h3>
</div>
</div>
<div class="content">
<h3>
<a href="item-details.html">
Tranquility (Modal)
</a>
</h3>
<span><i class="ri-heart-line"></i> 204</span>
</div>
</div>
<div class="trending-item">
<div class="trending-img">
<a href="item-details.html">
<img src="../assets/dab/images/trending/trending-img5.jpg" alt="Images">
</a>
<div class="trending-user">
<a href="author-profile.html" class="trending-user-option">
 <img src="../assets/dab/images/trending/trending-user5.jpg" alt="Images">
<span>Created by @Jekob</span>
</a>
</div>
<div class="trending-title">
<span>105 ETH 12/14</span>
<h3>Bid 60 ETH</h3>
</div>
</div>
<div class="content">
<h3>
<a href="item-details.html">
To Infinity
</a>
</h3>
<span><i class="ri-heart-line"></i> 204</span>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
export default {
    name: 'Activity',
    mounted() {
        document.title = 'Activity'
    },
}
</script>