<template>
<!-- Page Header Section Start Here -->
        <section class="page-header bg_img padding-tb">
            <div class="overlay"></div>
            <div class="container">
                <div class="page-header-content-area">
                    <h4 class="ph-title">Contact us</h4>
                    <ul class="lab-ul">
                        <li><a href="/">Home</a></li>
                        <li><a class="active">Contact us</a></li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- Page Header Section Ending Here -->

		<!-- Contact Us Section Start Here -->
        <div class="contact-section padding-tb bg-ash">
            <div class="container">
                <div class="contac-top">
                    <div class="row justify-content-center">
                        <div class="col-xl-4 col-lg-6 col-12">
                            <div class="contact-item">
                                <div class="contact-icon">
                                    <i class="icofont-google-map"></i>
                                </div>
                                <div class="contact-details">
                                    <p>123 Pham Tu, Khue Trung Ward, Cam Le District, Da Nang City, Viet Nam</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-12">
                            <div class="contact-item">
                                <div class="contact-icon">
                                    <i class="icofont-phone"></i>
                                </div>
                                <div class="contact-details">
                                    <p>+84 70 608 6777</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-12">
                            <div class="contact-item">
                                <div class="contact-icon">
                                    <i class="icofont-envelope"></i>
                                </div>
                                <div class="contact-details">
                                    <p>colienbeefshop@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contac-bottom">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-12">
                            <div class="location-map">
                                <div id="map">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3834.9583523251767!2d108.20493441485775!3d16.015683388914063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31421a2132f27a87%3A0x9cd01a54e5422790!2zMTIzIFBo4bqhbSBU4bupLCBLaHXDqiBUcnVuZywgQ-G6qW0gTOG7hywgxJDDoCBO4bq1bmcgNTUwMDAw!5e0!3m2!1svi!2s!4v1641016284142!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="contact-form">
                                <form action="#" method="POST" id="commentform" class="comment-form">
                                    <input type="text" name="name" class="" placeholder="Name*">
                                    <input type="email" name="email" class="" placeholder="Email*">
                                    <input type="text" name="subject" class="" placeholder="Subject*">
                                    <textarea name="text" id="role" cols="30" rows="10" placeholder="Message*"></textarea>
                                    <button type="submit" class="lab-btn">
                                        <span>Submit Now</span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact Us Section ENding Here -->
</template>
<script>
export default {
  	name: 'Home',
	mounted() {
    	document.title = 'Contact Us'
    } 
}
</script>