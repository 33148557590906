<template>
<div class="inner-banner inner-bg11">
<div class="container">
<div class="inner-title">
<h3>Collection</h3>
<ul>
<li>
<router-link to="/">Home</router-link>
</li>
<li>Pages</li>
<li>Collection</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="collection-widget-area pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-3">
<div class="author-profile-sidebar  mr-20">
<div class="author-user">
<img src="../assets/dab/images/collections/collection-profile.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Olivia Jenar</a></h3>
<span>@Jenar</span>
<p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary</p>
<div class="sp-title">0x76669f...a0e9ca52 <i class="ri-folders-line"></i></div>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2941</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="col-lg-9">
<div class="row justify-content-center">
<div class="col-lg-4 col-md-6">
<div class="featured-card box-shadow">
<div class="featured-card-img">
<a href="item-details.html">
<img src="../assets/dab/images/featured/featured-img1.jpg" alt="Images">
</a>
<p><i class="ri-heart-line"></i> 122</p>
<button type="button" class="default-btn border-radius-5">Place Bid</button>
</div>
<div class="content">
<h3><a href="item-details.html">Industrial Revolution</a></h3>
<div class="content-in">
<div class="featured-card-left">
<span>100 ETH 12/14</span>
<h4>Bid 80 ETH </h4>
</div>
<a href="item-details.html" class="featured-content-btn">
<i class="ri-arrow-right-line"></i>
</a>
</div>
<a href="author-profile.html" class="featured-user-option">
<img src="../assets/dab/images/featured/featured-user1.jpg" alt="Images">
<span>Created by @Adison</span>
</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="featured-card box-shadow">
<div class="featured-card-img">
<a href="item-details.html">
<img src="../assets/dab/images/featured/featured-img2.jpg" alt="Images">
</a>
<p><i class="ri-heart-line"></i> 142</p>
<div class="featured-card-clock" data-countdown="2021/10/10"></div>
<button type="button" class="default-btn border-radius-5">Place Bid</button>
</div>
<div class="content">
<h3><a href="item-details.html">I Love In The Air</a></h3>
<div class="content-in">
<div class="featured-card-left">
<span>110 ETH 12/14</span>
<h4>Bid 70 ETH </h4>
</div>
<a href="item-details.html" class="featured-content-btn">
<i class="ri-arrow-right-line"></i>
</a>
</div>
<a href="author-profile.html" class="featured-user-option">
<img src="../assets/dab/images/featured/featured-user2.jpg" alt="Images">
<span>Created by @Maicel</span>
</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="featured-card box-shadow">
<div class="featured-card-img">
<a href="item-details.html">
<img src="../assets/dab/images/featured/featured-img3.jpg" alt="Images">
</a>
<p><i class="ri-heart-line"></i> 162</p>
<div class="featured-card-clock" data-countdown="2021/09/09"></div>
<button type="button" class="default-btn border-radius-5">Place Bid</button>
</div>
<div class="content">
<h3><a href="item-details.html">Become One With Nature</a></h3>
<div class="content-in">
<div class="featured-card-left">
<span>120 ETH 12/14</span>
<h4>Bid 80 ETH </h4>
</div>
<a href="item-details.html" class="featured-content-btn">
<i class="ri-arrow-right-line"></i>
</a>
</div>
<a href="author-profile.html" class="featured-user-option">
<img src="../assets/dab/images/featured/featured-user3.jpg" alt="Images">
<span>Created by @Jekob</span>
</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="featured-card box-shadow">
<div class="featured-card-img">
<a href="item-details.html">
<img src="../assets/dab/images/featured/featured-img4.jpg" alt="Images">
</a>
<p><i class="ri-heart-line"></i> 192</p>
<button type="button" class="default-btn border-radius-5">Place Bid</button>
</div>
<div class="content">
<h3><a href="item-details.html">Twilight Fracture City</a></h3>
<div class="content-in">
<div class="featured-card-left">
<span>110 ETH 12/14</span>
<h4>Bid 90 ETH </h4>
</div>
<a href="item-details.html" class="featured-content-btn">
<i class="ri-arrow-right-line"></i>
</a>
</div>
<a href="author-profile.html" class="featured-user-option">
<img src="../assets/dab/images/featured/featured-user4.jpg" alt="Images">
<span>Created by @Jack</span>
</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="featured-card box-shadow">
<div class="featured-card-img">
<a href="item-details.html">
<img src="../assets/dab/images/featured/featured-img5.jpg" alt="Images">
</a>
<p><i class="ri-heart-line"></i> 142</p>
<button type="button" class="default-btn border-radius-5">Place Bid</button>
</div>
<div class="content">
<h3><a href="item-details.html">Walking On Air</a></h3>
<div class="content-in">
<div class="featured-card-left">
<span>130 ETH 12/14</span>
<h4>Bid 80 ETH </h4>
</div>
<a href="item-details.html" class="featured-content-btn">
<i class="ri-arrow-right-line"></i>
</a>
</div>
<a href="author-profile.html" class="featured-user-option">
<img src="../assets/dab/images/featured/featured-user5.jpg" alt="Images">
<span>Created by @Daniel</span>
 </a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="featured-card box-shadow">
<div class="featured-card-img">
<a href="item-details.html">
<img src="../assets/dab/images/featured/featured-img6.jpg" alt="Images">
</a>
<p><i class="ri-heart-line"></i> 172</p>
<button type="button" class="default-btn border-radius-5">Place Bid</button>
</div>
<div class="content">
<h3><a href="item-details.html">Supper Nuemorphism</a></h3>
<div class="content-in">
<div class="featured-card-left">
<span>140 ETH 12/14</span>
<h4>Bid 90 ETH </h4>
</div>
<a href="item-details.html" class="featured-content-btn">
<i class="ri-arrow-right-line"></i>
</a>
</div>
<a href="author-profile.html" class="featured-user-option">
<img src="../assets/dab/images/featured/featured-user6.jpg" alt="Images">
<span>Created by @Samuel</span>
</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="featured-card box-shadow">
<div class="featured-card-img">
<a href="item-details.html">
<img src="../assets/dab/images/featured/featured-img7.jpg" alt="Images">
</a>
<p><i class="ri-heart-line"></i> 182</p>
<button type="button" class="default-btn border-radius-5">Place Bid</button>
</div>
<div class="content">
<h3><a href="item-details.html">Dark-light Angel</a></h3>
<div class="content-in">
<div class="featured-card-left">
<span>160 ETH 12/14</span>
<h4>Bid 100 ETH </h4>
</div>
<a href="item-details.html" class="featured-content-btn">
<i class="ri-arrow-right-line"></i>
</a>
</div>
<a href="author-profile.html" class="featured-user-option">
<img src="../assets/dab/images/featured/featured-user7.jpg" alt="Images">
<span>Created by @Martina</span>
</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
 <div class="featured-card box-shadow">
<div class="featured-card-img">
<a href="item-details.html">
<img src="../assets/dab/images/featured/featured-img8.jpg" alt="Images">
</a>
<p><i class="ri-heart-line"></i> 142</p>
<button type="button" class="default-btn border-radius-5">Place Bid</button>
</div>
<div class="content">
<h3><a href="item-details.html">Exe Dream Hight</a></h3>
<div class="content-in">
<div class="featured-card-left">
<span>170 ETH 12/14</span>
<h4>Bid 90 ETH </h4>
</div>
<a href="item-details.html" class="featured-content-btn">
<i class="ri-arrow-right-line"></i>
</a>
</div>
<a href="author-profile.html" class="featured-user-option">
<img src="../assets/dab/images/featured/featured-user8.jpg" alt="Images">
<span>Created by @Henry</span>
</a>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="featured-card box-shadow">
<div class="featured-card-img">
<a href="item-details.html">
<img src="../assets/dab/images/featured/featured-img9.jpg" alt="Images">
</a>
<p><i class="ri-heart-line"></i> 132</p>
<button type="button" class="default-btn border-radius-5">Place Bid</button>
</div>
<div class="content">
<h3><a href="item-details.html">Art Of The Infinity</a></h3>
<div class="content-in">
<div class="featured-card-left">
<span>170 ETH 12/14</span>
<h4>Bid 90 ETH </h4>
</div>
<a href="item-details.html" class="featured-content-btn">
<i class="ri-arrow-right-line"></i>
</a>
</div>
<a href="author-profile.html" class="featured-user-option">
<img src="../assets/dab/images/featured/featured-user1.jpg" alt="Images">
<span>Created by @Jack</span>
</a>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


<div class="involved-area-two pt-100 pb-70">
<div class="container">
<div class="section-title text-center">
<h2>Get Involved</h2>
</div>
<div class="row pt-45">
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-flight-takeoff-line"></i>
</div>
<h3>Join Our <b>Community</b></h3>
<ul class="social-link">
<li>
<a href="https://www.google.com/" target="_blank">
<i class="ri-google-fill"></i>
</a>
</li>
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
<li>
<a href="https://www.linkedin.com/" target="_blank">
<i class="ri-linkedin-fill"></i>
</a>
</li>
</ul>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-checkbox-circle-line"></i>
</div>
<h3>Become A <b>Creator</b></h3>
<a href="register.html" class="default-btn">Register</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-heart-2-line"></i>
</div>
<h3>Become A <b>Charity Partner</b></h3>
<a href="create-collection.html" class="default-btn">Express Interest</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-discuss-line"></i>
</div>
<h3>Reach Out To <b>Our Team</b></h3>
<a href="contact.html" class="default-btn">Contact Now</a>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
export default {
    name: 'Collection',
    mounted() {
        document.title = 'Collection'
    },
}
</script>