import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import './assets/colien/css/animate.css'
import './assets/colien/css/bootstrap.min.css'
import './assets/colien/css/all.min.css'
import './assets/colien/css/icofont.min.css'
import './assets/colien/css/lightcase.css'
import './assets/colien/css/swiper.min.css'
import './assets/colien/css/style.css'

import './assets/colien/js/jquery.js'
import './assets/colien/js/fontawesome.min.js'
import './assets/colien/js/waypoints.min.js'
import '../node_modules/popper.js/dist/umd/popper.js'
import './assets/colien/js/bootstrap.min.js'
//import './assets/colien/js/wow.min.js'
//import './assets/colien/js/swiper.min.js'
import './assets/colien/js/jquery.countdown.min.js'
import './assets/colien/js/jquery.counterup.min.js'
//import './assets/colien/js/isotope.pkgd.min.js'
import './assets/colien/js/lightcase.js'
import './assets/colien/js/functions.js'


//Live
axios.defaults.baseURL = 'https://colienbeefshop.semode.com'
//Dev 
//axios.defaults.baseURL = 'http://colienbeefshop.com:1231'
createApp(App).use(store).use(router, axios).mount('#app')
