<template>
		<!-- Page Header Section Start Here -->
        <section class="page-header bg_img padding-tb">
            <div class="overlay"></div>
            <div class="container">
                <div class="page-header-content-area">
                    <h4 class="ph-title">{{item.title}}</h4>
                    <ul class="lab-ul">
                        <li><a href="/">Home</a></li>
                        <li><a class="active">{{item.title}}</a></li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- Page Header Section Ending Here -->
		<!-- Shop Page Section start here -->		            
        <section class="shop-single padding-tb pb-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-12 sticky-widget">
                        <div class="product-details">
                            <div class="row align-items-center">
                                <div class="col-md-6 col-12">
                                    <div class="product-thumb">
                                        <div class="swiper-container gallery-top">
                                            <div class="swiper-wrapper">
                                                <div class="swiper-slide">
                                                    <div class="shop-item">
                                                        <div class="shop-thumb">
                                                            <img src="../assets/colien/images/product/single/top/01.png" alt="shop-single">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="swiper-slide">
                                                    <div class="shop-item">
                                                        <div class="shop-thumb">
                                                            <img src="../assets/colien/images/product/single/top/01.png" alt="shop-single">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="swiper-slide">
                                                    <div class="shop-item">
                                                        <div class="shop-thumb">
                                                            <img src="../assets/colien/images/product/single/top/01.png" alt="shop-single">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="swiper-slide">
                                                    <div class="shop-item">
                                                        <div class="shop-thumb">
                                                            <img src="../assets/colien/images/product/single/top/01.png" alt="shop-single">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="swiper-slide">
                                                    <div class="shop-item">
                                                        <div class="shop-thumb">
                                                            <img src="../assets/colien/images/product/single/top/01.png" alt="shop-single">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="shop-navigation d-flex flex-wrap">
                                                <div class="shop-nav shop-slider-prev"><i class="icofont-simple-left"></i></div>
                                                <div class="shop-nav shop-slider-next"><i class="icofont-simple-right"></i></div>
                                            </div>
                                        </div>
                                        <div class="swiper-container gallery-thumbs">
                                            <div class="swiper-wrapper">
                                                <div class="swiper-slide">
                                                    <div class="shop-item">
                                                        <div class="shop-thumb">
                                                            <img src="../assets/colien/images/product/01.jpg" alt="shop-single">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="swiper-slide">
                                                    <div class="shop-item">
                                                        <div class="shop-thumb">
                                                            <img src="../assets/colien/images/product/02.jpg" alt="shop-single">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="swiper-slide">
                                                    <div class="shop-item">
                                                        <div class="shop-thumb">
                                                            <img src="../assets/colien/images/product/03.jpg" alt="shop-single">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="swiper-slide">
                                                    <div class="shop-item">
                                                        <div class="shop-thumb">
                                                            <img src="../assets/colien/images/product/04.jpg" alt="shop-single">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="swiper-slide">
                                                    <div class="shop-item">
                                                        <div class="shop-thumb">
                                                            <img src="../assets/colien/images/product/05.jpg" alt="shop-single">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12">
                                    <div class="post-content">
                                        <h4>{{item.title}}</h4>
                                        <p class="rating">
                                            <i class="icofont-ui-rating"></i>
                                            <i class="icofont-ui-rating"></i>
                                            <i class="icofont-ui-rating"></i>
                                            <i class="icofont-ui-rating"></i>
                                            <i class="icofont-ui-rating"></i>
                                            (3 review)
                                        </p>
                                        <h4>
                                            {{item.price}}
                                        </h4>
                                        <h5>
                                            Product Description
                                        </h5>
                                        <p>
                                           {{item.description}}
                                        </p>
                                        <form>
                                            <div class="select-product size">
                                                <select>
                                                    <option>Select Size</option>
                                                    <option>SM</option>
                                                    <option>MD</option>
                                                    <option>LG</option>
                                                    <option>XL</option>
                                                    <option>XXL</option>
                                                </select>
                                                <i class="fas fa-angle-down"></i>
                                            </div>
                                            <div class="select-product color">
                                                <select>
                                                    <option>Select Color</option>
                                                    <option>Pink</option>
                                                    <option>Ash</option>
                                                    <option>Red</option>
                                                    <option>White</option>
                                                    <option>Blue</option>
                                                </select>
                                                <i class="fas fa-angle-down"></i>
                                            </div>
                                            <div class="cart-plus-minus">
                                                <div class="dec qtybutton">-</div>
                                                <input class="cart-plus-minus-box" type="text" name="qtybutton" value="1">
                                                <div class="inc qtybutton">+</div>
                                            </div>
                                            <div class="discount-code">
                                                <input type="text" placeholder="Enter Discount Code">
                                            </div>
                                            <button type="submit">Add To Cart</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Shop Page Section ending here -->
</template>

<script>
import axios from 'axios'

export default {
    name: 'Item',
    data() {
        return {
            item: "",
            quantity: 1,
            user:{},
            eth_price:{},
        }
    },
    mounted() {
        this.getItem() 
        this.rate = this.$store.state.rate
        
    },
    methods: {
        async getItem() {
        	this.$store.commit('setIsLoading', true)
            const item_slug = this.$route.params.item_slug
            const id = this.$route.params.id

            await axios
                .get(`/api/items/${item_slug}/`)
                .then(response => {
                    this.item = response.data
                    document.title = this.item.title
                    this.eth_price = this.item.price/this.$store.state.rate.amount
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        addToCart() {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }

            const item = {
                product: this.product,
                quantity: this.quantity
            }

            this.$store.commit('addToCart', item)
        }
    }
}
</script>