<template>
		<!-- Page Header Section Start Here -->
        <section class="page-header bg_img padding-tb">
            <div class="overlay"></div>
            <div class="container">
                <div class="page-header-content-area">
                    <h4 class="ph-title">Blog Page</h4>
                    <ul class="lab-ul">
                        <li><a href="/">Home</a></li>
                        <li><a class="active">Blog</a></li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- Page Header Section Ending Here -->
		
        <!-- Blog Section Start Here -->
        <div class="blog-section blog-page padding-tb bg-ash">
            <div class="container">
                <div class="section-wrapper">
                    <div class="row justify-content-center flex-row-reverse">
                        <div class="col-lg-9 col-12">
                            <article>
                                <div class="post-item-2" v-for="post in posts">
									<div class="post-inner">
										<div class="post-thumb">
											<a :href="'/posts/'+post.slug+'/'">
												<img :src="post.featured_image" :alt="post.title">
											</a>
										</div>
										<div class="post-content">
											<a :href="'/posts/'+post.slug+'/'"><h3>{{post.title}}</h3></a>
											<p>{{post.description}}</p>
											<div class="d-flex flex-wrap justify-content-between more-com">
												<div class="text-btn">
													<a :href="'/posts/'+post.slug+'/'"><span>Read More<i class="fa fa-angle-double-right"></i></span></a>
												</div>
											</div>
										</div>
									</div>
								</div>
								
                                <div class="paginations">
                                    <ul class="lab-ul d-flex flex-wrap justify-content-center">
                                        <li>
                                            <a href="#">1</a>
                                        </li>
                                        <li class="d-none d-sm-block">
                                            <a href="#">2</a>
                                        </li>
                                        <li class="d-none d-sm-block">
                                            <a href="#">3</a>
                                        </li>
                                        <li>
                                            <a class="dot">...</a>
                                        </li>
                                        <li class="d-none d-sm-block">
                                            <a href="#">9</a>
                                        </li>
                                        <li class="d-none d-sm-block">
                                            <a href="#">10</a>
                                        </li>
                                        <li>
                                            <a href="#">11</a>
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                        <div class="col-lg-3 col-md-7 col-12">
                            <aside>
                                <div class="widget widget-category">
                                    <div class="widget-header">
                                        <h5>Post Categories</h5>
                                    </div>
                                    <ul class="lab-ul widget-wrapper">
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Show all</span><span>(148)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Business</span><span>(20)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Creative</span><span>(25)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Inspiation</span><span>(30)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>News</span><span>(28)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Photography</span><span>(20)</span></a>
                                        </li>
                                        <li>
                                            <a href="#" class="d-flex flex-wrap justify-content-between"><span>Smart</span><span>(25)</span></a>
                                        </li>
                                    </ul>
                                </div>
            
                                <div class="widget widget-post">
                                    <div class="widget-header">
                                        <h5>Recent Post</h5>
                                    </div>
                                    <ul class="lab-ul widget-wrapper">
                                        <li class="d-flex flex-wrap justify-content-between">
                                            <div class="post-thumb">
                                                <a href="blog-single.html"><img src="../assets/colien/images/product/01.jpg" alt="product"></a>
                                            </div>
                                            <div class="post-content">
												<a href="blog-single.html"><h6>Conveniently utilize premier metho.</h6></a>
                                                <p>04 February 2019</p>
                                            </div>
										</li>
										<li class="d-flex flex-wrap justify-content-between">
                                            <div class="post-thumb">
                                                <a href="blog-single.html"><img src="../assets/colien/images/product/02.jpg" alt="product"></a>
                                            </div>
                                            <div class="post-content">
												<a href="blog-single.html"><h6>Seamlessly fashion customiz before.</h6></a>
                                                <p>04 February 2019</p>
                                            </div>
										</li>
										<li class="d-flex flex-wrap justify-content-between">
                                            <div class="post-thumb">
                                                <a href="blog-single.html"><img src="../assets/colien/images/product/03.jpg" alt="product"></a>
                                            </div>
                                            <div class="post-content">
												<a href="blog-single.html"><h6>Conveniently utilize premier metho.</h6></a>
                                                <p>04 February 2019</p>
                                            </div>
										</li>
										<li class="d-flex flex-wrap justify-content-between">
                                            <div class="post-thumb">
                                                <a href="blog-single.html"><img src="../assets/colien/images/product/04.jpg" alt="product"></a>
                                            </div>
                                            <div class="post-content">
												<a href="blog-single.html"><h6>Seamlessly fashion customiz before.</h6></a>
                                                <p>04 February 2019</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="widget widget-instagram">
                                    <div class="widget-header">
                                        <h5>dokan instagram</h5>
                                    </div>
                                    <ul class="lab-ul widget-wrapper d-flex flex-wrap justify-content-center">
                                        <li><a href="#"><img src="../assets/colien/images/team/01.jpg" alt="team"></a></li>
                                        <li><a href="#"><img src="../assets/colien/images/team/02.jpg" alt="team"></a></li>
                                        <li><a href="#"><img src="../assets/colien/images/team/05.jpg" alt="team"></a></li>
                                        <li><a href="#"><img src="../assets/colien/images/team/06.jpg" alt="team"></a></li>
                                    </ul>
                                </div>

                                <div class="widget widget-tags">
                                    <div class="widget-header">
                                        <h5>top tags</h5>
                                    </div>
                                    <ul class="lab-ul widget-wrapper">
                                        <li><a href="#">envato</a></li>
                                        <li><a href="#">themeforest</a></li>
                                        <li><a href="#">codecanyon</a></li>
                                        <li><a href="#">videohive</a></li>
                                        <li><a href="#">audiojungle</a></li>
                                        <li><a href="#">3docean</a></li>
                                        <li><a href="#">envato</a></li>
                                        <li><a href="#">themeforest</a></li>
                                        <li><a href="#">codecanyon</a></li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Blog Section ENding Here -->
</template>
<script>
import axios from 'axios'

export default {
    name: 'Blog',
    data() {
        return {
            posts: {}
        }
    },
    mounted() {
        document.title = 'Blog'
        this.getPosts()
    },
    methods: {
    	async getPosts() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/posts/')
                .then(response => {
                    this.posts = response.data.results
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>