<template>
	<div class="inner-banner inner-bg4">
		<div class="container">
			<div class="inner-title">
				<h3>Log In</h3>
			<ul>
				<li>
					<a href="/">Home</a>
				</li>
				<li>Pages</li>
				<li>Log In</li>
			</ul>
			<div class="inner-shape">
				<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
				<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
			</div>
			</div>
		</div>
	</div>
	<div class="user-area pt-100 pb-70">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-12">
					<div class="user-all-form">
					<div class="contact-form">

                    <h3>Log in</h3>

                    <form @submit.prevent="submitForm">
                    <div class="row">
						<div class="col-lg-12 ">
							<div class="form-group">
								<label>Email</label>
								<input type="text" class="form-control" v-model="email">
							</div>
						</div>
						
						<div class="col-12">
							<div class="form-group">
								<label>Password</label>
								<input type="password" class="form-control" v-model="password">
							</div>
						</div>

                    	<div class="col-lg-12">
	                        <div class="notification is-danger" v-if="errors.length">
	                            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
	                        </div>
						</div>
						<div class="col-lg-12 form-condition">
							<div class="agree-label">
								<input type="checkbox" id="chb1">
								<label for="chb1">
								Remember Me <a class="forget" href="/forgot-password/">Forgot My Password?</a>
								</label>
							</div>
						</div>
						<div class="col-lg-12 col-md-12 text-center">
							<button type="submit" class="default-btn">
								Log In
							</button>
						</div>
                        <div class="col-12">
							<div class="sub-title">
								<span>Or</span>
							</div>
						</div>
						<div class="login-with-account">
							<ul>
								<li>
									<a href="/register/" target="_blank">
										Register
									</a>
								</li>
							</ul>
						</div>
                    </div>
                    </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'LogIn',
    data() {
        return {
            email: '',
            password: '',
            errors: []
        }
    },
    mounted() {
        document.title = 'Log In | Digital Asset Bank'
    },
    methods: {
        async submitForm() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")

            const formData = {
                email: this.email,
                password: this.password
            }

            await axios
                .post("/api/v1/token/login/", formData)
                .then(response => {
                    console.log(response.data)
                    const token = response.data.auth_token
                    this.$store.commit('setToken', token)
                    
                    axios.defaults.headers.common["Authorization"] = "Token " + token
					this.$store.state.user = response.data.user
					
                    localStorage.setItem("token", token)
					this.$store.commit('setUser',response.data.user)
					//localStorage.setItem("user", response.data.user)
                    const toPath = this.$route.query.to || '/'
                    this.$router.push(toPath)
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        
                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>