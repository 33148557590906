<template>
<!-- Banner Section Start Here -->
		<section class="banner">
            <div class="banner-slider">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="banner-slider-part" style="background-image: url(assets/images/banner/bg-images/01.jpg);">
							<div class="overlay"></div>
                            <div class="container">
                                <div class="row flex-row-reverse justify-content-center align-items-center">
									<div class="col-xl-6 col-12">
										<div class="banner-thumb">
											<img src="../assets/colien/images/banner/01.png" alt="banner-thumb">
										</div>
									</div>
									<div class="col-xl-6 col-md-10 col-12">
										<div class="banner-content">
											<h2 class="mb-3 bt-thumb">Co Lien Beef Shop</h2>
											<h3 class="banner-title">Best Meat Providers In Your Town </h3>
											<div class="banner-desc">Dynamically supply web-enabled portals for high standards income and business Completely productivate optimal sources rather than strategic.</div>
											<a href="#" class="lab-btn"><span>View Product</span></a>
										</div>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
						<div class="banner-slider-part" style="background-image: url(assets/images/banner/bg-images/01.jpg);">
							<div class="overlay"></div>
                            <div class="container">
                                <div class="row justify-content-center align-items-center">
									<div class="col-xl-6 col-12">
										<div class="banner-thumb">
											<img src="../assets/colien/images/banner/01.png" alt="banner-thumb">
										</div>
									</div>
									<div class="col-xl-6 col-md-10 col-12">
										<div class="banner-content">
											<h2 class="mb-3 bt-thumb">Co Lien Beef Shop</h2>
											<h3 class="banner-title">Best Meat Providers In Your Town </h3>
											<div class="banner-desc">Dynamically supply web-enabled portals for high standards income and business Completely productivate optimal sources rather than strategic.</div>
											<a href="#" class="lab-btn"><span>View Product</span></a>
										</div>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="banner-slider-part" style="background-image: url(assets/images/banner/bg-images/02.jpg);">
							<div class="overlay"></div>
                            <div class="container">
                                <div class="row flex-row-reverse justify-content-center align-items-center">
									<div class="col-xl-6 col-12">
										<div class="banner-thumb">
											<img src="../assets/colien/images/banner/01.png" alt="banner-thumb">
										</div>
									</div>
									<div class="col-xl-6 col-md-10 col-12">
										<div class="banner-content">
											<h2 class="mb-3 bt-thumb">Co Lien Beef Shop</h2>
											<h3 class="banner-title">Best Meat Providers In Your Town </h3>
											<div class="banner-desc">Dynamically supply web-enabled portals for high standards income and business Completely productivate optimal sources rather than strategic.</div>
											<a href="#" class="lab-btn"><span>View Product</span></a>
										</div>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
				</div>
				<div class="banner-next banner-nav"><i class="icofont-rounded-left"></i></div>
				<div class="banner-prev banner-nav"><i class="icofont-rounded-right"></i></div>
            </div>
        </section>
		<!-- Banner Section Ending Here -->

		<!-- about section start here -->
		<section class="about-section bg_img relative" style="background: url(assets/images/about/bg-image.jpg);">
			<div class="shape-images">
				<img src="../assets/colien/images/shape-images/04.png" alt="shape-images">
			</div>
			<div class="container">
				<div class="row">
					<div class="col-lg-6 col-12">
						<div class="about-thumb ab-images">
							<img src="../assets/colien/images/about/01.png" alt="about-thumb">
						</div>
					</div>
					<div class="col-lg-6 col-12">
						<div class="about-wrapper">
							<div class="about-title">
								<h3>About</h3>
								<p>Continually productize compelling quality for packed with elated Productize compelling quality for packed with all elated themv Setting up to website and creating pages.</p>
							</div>
							<div class="about-content">
								<ul class="lab-ul list-group">
									<li class="list-group-item py-1 px-0 border-none"><i class="icofont-tick-boxed mr-2 color-theme"></i>We are providing different services</li>
									<li class="list-group-item py-1 px-0 border-none"><i class="icofont-tick-boxed mr-2 color-theme"></i>We are one of leading company</li>
									<li class="list-group-item py-1 px-0 border-none"><i class="icofont-tick-boxed mr-2 color-theme"></i>Profitability is the primary goal of all business</li>
									<li class="list-group-item py-1 px-0 border-none"><i class="icofont-tick-boxed mr-2 color-theme"></i>Learn how to grow your Business</li>
									<li class="list-group-item py-1 px-0 border-none"><i class="icofont-tick-boxed mr-2 color-theme"></i>Professional solutions for your business</li>
								</ul>
								<a href="#" class="lab-btn mt-4"><span>Read More</span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- about section ending here -->

		<!-- product category section start here -->
		<section class="product-category-section padding-tb bg-ash">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="section-header">
							<h3>Daily Special Rate</h3>
							<p>Conveniently customize proactive  services leveraged interfaces without Globally </p>
						</div>
					</div>
					<div class="col-12">
						<div class="section-wrapper">
							<div class="row">
								<div class="col-lg-4 col-12">
									<ul class="nav lab-ul" id="myTab" role="tablist">
										<li class="nav-item w-100">
											<a class="nav-link d-flex align-items-center active" id="mutton-tab" data-toggle="tab" href="#mutton" role="tab" aria-controls="mutton" aria-selected="true">
												<img src="../assets/colien/images/products/category/icon/01.jpg" alt="category-icon" class="mr-3 rounded-circle">
												<h6 class="mb-0">Mutton</h6>
											</a>
										</li>
										<li class="nav-item w-100">
											<a class="nav-link d-flex align-items-center" id="beef-tab" data-toggle="tab" href="#beef" role="tab" aria-controls="beef" aria-selected="false">
												<img src="../assets/colien/images/products/category/icon/01.jpg" alt="category-icon" class="mr-3 rounded-circle">
												<h6 class="mb-0">Beef</h6>
											</a>
										</li>
										<li class="nav-item w-100">
											<a class="nav-link d-flex align-items-center" id="beef-veal-tab" data-toggle="tab" href="#beef-veal" role="tab" aria-controls="beef-veal" aria-selected="false">
												<img src="../assets/colien/images/products/category/icon/01.jpg" alt="category-icon" class="mr-3 rounded-circle">
												<h6 class="mb-0">Beef Veal</h6>
											</a>
										</li>
										<li class="nav-item w-100">
											<a class="nav-link d-flex align-items-center" id="chicken-tab" data-toggle="tab" href="#chicken" role="tab" aria-controls="chicken" aria-selected="false">
												<img src="../assets/colien/images/products/category/icon/01.jpg" alt="category-icon" class="mr-3 rounded-circle">
												<h6 class="mb-0">Chicken</h6>
											</a>
										</li>
										<li class="nav-item w-100">
											<a class="nav-link d-flex align-items-center" id="pork-tab" data-toggle="tab" href="#pork" role="tab" aria-controls="pork" aria-selected="false">
												<img src="../assets/colien/images/products/category/icon/01.jpg" alt="category-icon" class="mr-3 rounded-circle">
												<h6 class="mb-0">Pork</h6>
											</a>
										</li>
									</ul>
								</div>
								<div class="col-lg-8 col-12">
									<div class="tab-content" id="myTabContent">
										<div class="tab-pane fade show active" id="mutton" role="tabpanel">
											<div class="card p-3 border-none">
												<div class="row no-gutters align-items-center flex-row-reverse">
												<div class="col-md-7">
													<img src="../assets/colien/images/products/category/01.jpg" class="card-img" alt="p-category">
												</div>
												<div class="col-md-5">
													<div class="card-body">
														<h5 class="card-title mb-1">Mutton</h5>
														<p class="mb-1"><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i></p>
														<p>(5 customer review)</p>
														<h6 class="product-price">$10.5 /Kg</h6>
														<p class="card-text">Continually productize compeline for packed with elated ductie compeling with elated ductize compelling.</p>
														<a href="#" class="lab-btn mt-2"><span>Shop Now</span></a>
													</div>
												</div>
												</div>
											</div>
										</div>
										<div class="tab-pane fade" id="beef" role="tabpanel" aria-labelledby="beef-tab">
											<div class="card p-3 border-none">
												<div class="row no-gutters align-items-center flex-row-reverse">
												<div class="col-md-7">
													<img src="../assets/colien/images/products/category/01.jpg" class="card-img" alt="p-category">
												</div>
												<div class="col-md-5">
													<div class="card-body">
														<h5 class="card-title mb-1">Beef</h5>
														<p class="mb-1"><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i></p>
														<p>(5 customer review)</p>
														<h6 class="product-price">$10.5 /Kg</h6>
														<p class="card-text">Continually productize compeline for packed with elated ductie compeling with elated ductize compelling.</p>
														<a href="#" class="lab-btn mt-2"><span>Shop Now</span></a>
													</div>
												</div>
												</div>
											</div>
										</div>
										<div class="tab-pane fade" id="beef-veal" role="tabpanel" aria-labelledby="beef-veal-tab">
											<div class="card p-3 border-none">
												<div class="row no-gutters align-items-center flex-row-reverse">
												<div class="col-md-7">
													<img src="../assets/colien/images/products/category/01.jpg" class="card-img" alt="p-category">
												</div>
												<div class="col-md-5">
													<div class="card-body">
														<h5 class="card-title mb-1">Beef Veal</h5>
														<p class="mb-1"><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i></p>
														<p>(5 customer review)</p>
														<h6 class="product-price">$10.5 /Kg</h6>
														<p class="card-text">Continually productize compeline for packed with elated ductie compeling with elated ductize compelling.</p>
														<a href="#" class="lab-btn"><span>Shop Now</span></a>
													</div>
												</div>
												</div>
											</div>
										</div>
										<div class="tab-pane fade" id="chicken" role="tabpanel" aria-labelledby="chicken-tab">
											<div class="card p-3 border-none">
												<div class="row no-gutters align-items-center flex-row-reverse">
												<div class="col-md-7">
													<img src="../assets/colien/images/products/category/01.jpg" class="card-img" alt="p-category">
												</div>
												<div class="col-md-5">
													<div class="card-body">
														<h5 class="card-title mb-1">Chicken</h5>
														<p class="mb-1"><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i></p>
														<p>(5 customer review)</p>
														<h6 class="product-price">$10.5 /Kg</h6>
														<p class="card-text">Continually productize compeline for packed with elated ductie compeling with elated ductize compelling.</p>
														<a href="#" class="lab-btn mt-2"><span>Shop Now</span></a>
													</div>
												</div>
												</div>
											</div>
										</div>
										<div class="tab-pane fade" id="pork" role="tabpanel" aria-labelledby="pork-tab">
											<div class="card p-3 border-none">
												<div class="row no-gutters align-items-center flex-row-reverse">
												<div class="col-md-7">
													<img src="../assets/colien/images/products/category/01.jpg" class="card-img" alt="p-category">
												</div>
												<div class="col-md-5">
													<div class="card-body">
														<h5 class="card-title mb-1">Pork</h5>
														<p class="mb-1"><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i></p>
														<p>(5 customer review)</p>
														<h6 class="product-price">$10.5 /Kg</h6>
														<p class="card-text">Continually productize compeline for packed with elated ductie compeling with elated ductize compelling.</p>
														<a href="#" class="lab-btn mt-2"><span>Shop Now</span></a>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- product category section ending here -->

		<!-- product category section start here -->
		<section class="product-section relative padding-tb">
			<div class="shape-images">
				<img src="../assets/colien/images/shape-images/01.png" alt="shape-images">
			</div>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="section-header">
							<h3>Product</h3>
							<p>Conveniently customize proactive  services leveraged interfaces without Globally </p>
						</div>
					</div>
					<div class="col-12">
						<div class="section-wrapper">
							<div class="row justify-content-center">
								<div class="col-xl-3 col-lg-4 col-sm-6 col-12">
									<div class="card py-4 px-2 mb-4 text-center bg-ash border-none relative">
										<span class="lab-offer">-50%</span>
										<img src="../assets/colien/images/products/product/01.png" class="card-img-top mb-2" alt="product">
										<div class="card-body">
											<a href="#"><h6 class="card-title mb-2">Fresh Mutton Cuts</h6></a>
											<p class="mb-2"><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i></p>
											<h6 class="product-price">$10.5 /Kg</h6>
										</div>
									</div>
								</div>
								<div class="col-xl-3 col-lg-4 col-sm-6 col-12">
									<div class="card py-4 px-2 mb-4 text-center bg-ash border-none relative">
										<img src="../assets/colien/images/products/product/02.png" class="card-img-top mb-2" alt="product">
										<div class="card-body">
											<a href="#"><h6 class="card-title mb-2">Fresh Mutton Cuts</h6></a>
											<p class="mb-2"><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i></p>
											<h6 class="product-price">$10.5 /Kg</h6>
										</div>
									</div>
								</div>
								<div class="col-xl-3 col-lg-4 col-sm-6 col-12">
									<div class="card py-4 px-2 mb-4 text-center bg-ash border-none relative">
										<span class="lab-offer">-50%</span>
										<img src="../assets/colien/images/products/product/03.png" class="card-img-top mb-2" alt="product">
										<div class="card-body">
											<a href="#"><h6 class="card-title mb-2">Fresh Mutton Cuts</h6></a>
											<p class="mb-2"><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i></p>
											<h6 class="product-price">$10.5 /Kg</h6>
										</div>
									</div>
								</div>
								<div class="col-xl-3 col-lg-4 col-sm-6 col-12">
									<div class="card py-4 px-2 mb-4 text-center bg-ash border-none relative">
										<img src="../assets/colien/images/products/product/01.png" class="card-img-top mb-2" alt="product">
										<div class="card-body">
											<a href="#"><h6 class="card-title mb-2">Fresh Mutton Cuts</h6></a>
											<p class="mb-2"><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i><i class="icofont-ui-rating color-theme"></i></p>
											<h6 class="product-price">$10.5 /Kg</h6>
										</div>
									</div>
								</div>
							</div>
							<div class="text-center mt-3">
								<a href="#" class="lab-btn"><span>Shop Now</span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- product category section ending here -->

		<!-- newsletters section start here -->
		<div class="newsletter-section relative bg-ash">
			<div class="shape-images">
				<img src="../assets/colien/images/shape-images/02.png" alt="shape-images">
			</div>
			<div class="container">
				<div class="row justify-content-center align-items-center">
					<div class="col-lg-8 col-12">
						<div class="newsletter-form text-center">
							<div class="news-title mb-5">
								<h4 class="mb-0"><span>Subscribe our newsletter to get more update.</span></h4>
								<h2>Went to Get Started</h2>
							</div>
							<form action="https://labartisan.net/" class="d-flex flex-wrap">
								<input type="email" placeholder="Enter your Email" class="input-email">
								<input type="submit" value="Subscribe" class="subscribe-btn">
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- newsletters section ending here -->

		<!-- team section start here -->
		<section class="team-section padding-tb">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="section-header">
							<h3>Butcher�s Team</h3>
							<p>Conveniently customize proactive  services leveraged interfaces without Globally </p>
						</div>
					</div>
					<div class="col-12">
						<div class="section-wrapper">
							<div class="row justify-content-center">
								<div class="col-xl-3 col-lg-4 col-sm-6 col-12">
									<div class="card p-2 mb-4 text-center border-none">
										<img src="../assets/colien/images/team/01.jpg" class="card-img-top" alt="product">
										<div class="card-body">
											<a href="#"><h6 class="card-title mb-0">Alisha Kabir</h6></a>
											<p class="card-text mb-2">Marketer</p>
											<div class="social-share">
												<a href="#" class="m-1 twitter"><i class="icofont-twitter"></i></a>
												<a href="#" class="m-1 behance"><i class="icofont-behance"></i></a>
												<a href="#" class="m-1 instagram"><i class="icofont-instagram"></i></a>
												<a href="#" class="m-1 vimeo"><i class="icofont-vimeo"></i></a>
												<a href="#" class="m-1 linkedin"><i class="icofont-linkedin"></i></a>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-3 col-lg-4 col-sm-6 col-12">
									<div class="card p-2 mb-4 text-center border-none">
										<img src="../assets/colien/images/team/02.jpg" class="card-img-top" alt="product">
										<div class="card-body">
											<a href="#"><h6 class="card-title mb-0">Sahjahan Sagor</h6></a>
											<p class="card-text mb-2">Founder & Ceo</p>
											<div class="social-share">
												<a href="#" class="m-1 twitter"><i class="icofont-twitter"></i></a>
												<a href="#" class="m-1 behance"><i class="icofont-behance"></i></a>
												<a href="#" class="m-1 instagram"><i class="icofont-instagram"></i></a>
												<a href="#" class="m-1 vimeo"><i class="icofont-vimeo"></i></a>
												<a href="#" class="m-1 linkedin"><i class="icofont-linkedin"></i></a>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-3 col-lg-4 col-sm-6 col-12">
									<div class="card p-2 mb-4 text-center border-none">
										<img src="../assets/colien/images/team/03.jpg" class="card-img-top" alt="product">
										<div class="card-body">
											<a href="#"><h6 class="card-title mb-0">Lubna Smith</h6></a>
											<p class="card-text mb-2">Marketer</p>
											<div class="social-share">
												<a href="#" class="m-1 twitter"><i class="icofont-twitter"></i></a>
												<a href="#" class="m-1 behance"><i class="icofont-behance"></i></a>
												<a href="#" class="m-1 instagram"><i class="icofont-instagram"></i></a>
												<a href="#" class="m-1 vimeo"><i class="icofont-vimeo"></i></a>
												<a href="#" class="m-1 linkedin"><i class="icofont-linkedin"></i></a>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-3 col-lg-4 col-sm-6 col-12">
									<div class="card p-2 mb-4 text-center border-none">
										<img src="../assets/colien/images/team/04.jpg" class="card-img-top" alt="product">
										<div class="card-body">
											<a href="#"><h6 class="card-title mb-0">Jeson Roy</h6></a>
											<p class="card-text mb-2">Manager</p>
											<div class="social-share">
												<a href="#" class="m-1 twitter"><i class="icofont-twitter"></i></a>
												<a href="#" class="m-1 behance"><i class="icofont-behance"></i></a>
												<a href="#" class="m-1 instagram"><i class="icofont-instagram"></i></a>
												<a href="#" class="m-1 vimeo"><i class="icofont-vimeo"></i></a>
												<a href="#" class="m-1 linkedin"><i class="icofont-linkedin"></i></a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- team section ending here -->

		<!-- blog section start here -->
		<section class="blog-section relative padding-tb bg-ash">
			<div class="shape-images">
				<img src="../assets/colien/images/shape-images/01.png" alt="shape-images">
			</div>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="section-header">
							<h3>Recent Blog</h3>
							<p>Conveniently customize proactive  services leveraged interfaces without Globally</p>
						</div>
					</div>
					<div class="col-12">
						<div class="section-wrapper">
							<div class="row justify-content-center">
								<div class="col-lg-4 col-sm-6 col-12">
									<div class="card text-center border-none">
										<img src="../assets/colien/images/blog/01.jpg" class="card-img-top" alt="blog">
										<div class="card-body">
											<a href="#"><h6 class="card-title">Bloke cracking goal the full hic sed consequuntu...</h6></a>
											<p class="card-by"><a href="#">January 26, 2019,</a><a href="#">15 likes</a><a href="#">5 Comments</a></p>
										  	<p class="card-text mb-4">Conveniently customize proactive web services without Globally Continually leverage existing interoperable initiatives through</p>
										  	<a href="#" class="lab-btn"><span>Read More</span></a>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-sm-6 col-12">
									<div class="card text-center border-none">
										<img src="../assets/colien/images/blog/02.jpg" class="card-img-top" alt="blog">
										<div class="card-body">
											<a href="#"><h6 class="card-title">Victoria sponge horse play laud antium conubia...</h6></a>
											<p class="card-by"><a href="#">January 26, 2019,</a><a href="#">15 likes</a><a href="#">5 Comments</a></p>
										  	<p class="card-text mb-4">Conveniently customize proactive web services without Globally Continually leverage existing interoperable initiatives through</p>
										  	<a href="#" class="lab-btn"><span>Read More</span></a>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-sm-6 col-12">
									<div class="card text-center border-none">
										<img src="../assets/colien/images/blog/03.jpg" class="card-img-top" alt="blog">
										<div class="card-body">
											<a href="#"><h6 class="card-title">We craft marketing & digital products that ...</h6></a>
											<p class="card-by"><a href="#">January 26, 2019,</a><a href="#">15 likes</a><a href="#">5 Comments</a></p>
										  	<p class="card-text mb-4">Conveniently customize proactive web services without Globally Continually leverage existing interoperable initiatives through</p>
										  	<a href="#" class="lab-btn"><span>Read More</span></a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- blog section ending here -->

		<!-- sponsor section start here -->
		<div class="sponsor-section padding-tb">
			<div class="container">
				<div class="section-wrapper">
					<div class="sponsor-slider">
						<div class="swiper-wrapper">
							<div class="swiper-slide">
								<div class="sponsor-item">
									<div class="sponsor-thumb">
										<a href="#"><img src="../assets/colien/images/sponsor/01.png" alt="sponsor"></a>
									</div>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="sponsor-item">
									<div class="sponsor-thumb">
										<a href="#"><img src="../assets/colien/images/sponsor/02.png" alt="sponsor"></a>
									</div>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="sponsor-item">
									<div class="sponsor-thumb">
										<a href="#"><img src="../assets/colien/images/sponsor/03.png" alt="sponsor"></a>
									</div>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="sponsor-item">
									<div class="sponsor-thumb">
										<a href="#"><img src="../assets/colien/images/sponsor/04.png" alt="sponsor"></a>
									</div>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="sponsor-item">
									<div class="sponsor-thumb">
										<a href="#"><img src="../assets/colien/images/sponsor/05.png" alt="sponsor"></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- sponsor section ending here -->
</template>

<script>
import VueMetamask from 'vue-metamask';
import axios from 'axios'
export default {
  name: 'Home',
  components: {
  	VueMetamask,
  },
  data() {
    return {
      biz_resp_data: "",
      metamask_address: "",
      msg: "This is demo net work",
      trendingArtworks:[],
      topSellers:[],
    }
  },
  filters: {
    toCurrency(value) {
        if (typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    }
  },
  methods: {
    onComplete(data){
    	console.log('data:', data);
    	console.log(data['metaMaskAddress']);
    	if(!data['metaMaskAddress']) {
    		alert("Please download Metamask to mint NFT. Link: https://metamask.io/download.html");
    		
    	}
    	this.$store.commit('setMetamaskWallet', data);
    	this.metamask_address=data['metaMaskAddress']
    },
    async processBizname() {
      this.$store.commit('setIsLoading', true)
      await axios
        .get('/api/process-bizname/', {
          params: {
            bizname: this.bizname
          }
        })
        .then(response => {
          this.biz_resp_data = response.data
			
          console.log(this.biz_resp_data)

        })
        .catch(error => {
          console.log(error)
        })
        this.$store.commit('setIsLoading', false)
    },
    async getMiscData() {
      this.$store.commit('setIsLoading', true)

      await axios
        .get('/api/misc-data/')
        .then(response => {
          this.$store.commit('setMiscData', response.data)
          this.mainMenus = response.data.menus.main_menus
          this.$store.state.mainMenus = response.data.menus.main_menus
          this.$store.state.footerMenuitems = response.data.menus.footer_menuitems
          this.$store.state.homeSliders = response.data.sliders.home_sliders
          this.$store.state.storages = response.data.storages
          this.$store.state.topSellers = response.data.top_sellers
          this.topSellers=response.data.top_sellers 
          
        })
        .catch(error => {
          console.log(error)
        })

      this.$store.commit('setIsLoading', false)
    },
    formatPrice(value) {
        //let val = (value/1)
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    goto(url){
		window.location.href = url
	},
  },
  
  mounted() {
    document.title = 'Home'
    this.getMiscData()
    this.topSellers = this.$store.state.topSellers
    this.trendingArtworks = this.$store.state.trendingArtworks
  },
  updated() {
  	$('.trending-slider').owlCarousel({
	    loop:true,
	    margin:10,
	    dots:false,
	    responsiveClass:true,
	    responsive:{
	        0:{
	            items:1,
	            nav:true
	        },
	        600:{
	            items:3,
	            nav:true 
	        },
	        1000:{
	            items:4,
	            nav:true,
	            loop:false
	        }
	    }
	})
	$('.auctions-slider').owlCarousel({
	    loop:true,
	    margin:10,
	    responsiveClass:true,
	    responsive:{
	        0:{
	            items:1,
	            nav:false
	        },
	        600:{
	            items:2,
	            nav:false
	        },
	        1000:{
	            items:3,
	            nav:false,
	            loop:false
	        }
	    }
	})
  }
}

</script>