<template>
		<!-- fore zero fore page start here -->
		<section class="fore-zero padding-tb">
            <div class="container">
                <div class="section-wrapper">
                    <div class="zero-item text-center">
                        <h2>Oops, sorry we can't find that page</h2>
                        <div class="zero-thumb">
                            <img src="../assets/colien/images/404.png" alt="404">
                        </div>
                        <div class="zero-content">
                            <a href="/" class="lab-btn"><span>Back to Home</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- fore zero fore page ending here -->
</template>
<script>
export default {
    name: 'NotFound',
    mounted() {
        document.title = '404 Not Found'
    },
}
</script>