import { createStore } from 'vuex'

export default createStore({
  state: {
    cart: {
        items: [],
    },
    isAuthenticated: false,
    token: '',
	user: {},
    isLoading: false,
    miscData:{},
    mainMenus:[],
    footerMenuitems:[],
    homeSliders: [],
    storages: {},
	topSellers:[],
	metamaskWallet:{},
	tokenUri:{},
	trendingArtworks:[],
	toast: {
    	text: "",
    	show: false
    },
	rate:{},
  },
	getters: {
        toast: (state) => {
            return state.toast;
        }
    },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }

      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      } 
		
	  if (localStorage.getItem('user')) {
        state.user = JSON.parse(localStorage.getItem('user'))
      } else {
        localStorage.setItem('user', JSON.stringify(state.user))
      }

	  if (localStorage.getItem('rate')) {
        state.rate = JSON.parse(localStorage.getItem('rate'))
      } else {
        localStorage.setItem('rate', JSON.stringify(state.rate))
      }
		
    },
    addToCart(state, item) {
      const exists = state.cart.items.filter(i => i.product.id === item.product.id)
      if (exists.length) {
        exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
      } else {
        state.cart.items.push(item)
      }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setMiscData(state, miscData) {
      state.miscData = miscData
    },
    setToken(state, token) {
        state.token = token
        state.isAuthenticated = true
    },  
    setMetamaskWallet(state,data) {
		state.metamaskWallet=data
		localStorage.setItem("metamaskWallet",data)
	},
	setTokenUri(state,data) {
		state.tokenUri=data
	},
	setRate(state,data) {
		state.rate=data
		localStorage.setItem('rate', JSON.stringify(state.rate))
	},
	setTrendingArtworks(state,data) {
		state.trendingArtworks=data
		localStorage.setItem('trendingArtworks', JSON.stringify(data))
	},
    removeToken(state) {
        state.token = ''
        state.isAuthenticated = false
    },
    clearCart(state) {
      state.cart = { items: [] }
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
	showToast: (state, toastText) => {
            state.toast.show = true;
            state.toast.text = toastText;
        },
    hideToast: (state) => {
            state.toast.show = false;
            state.toast.text = "";
        }
  },
  actions: {
  },
  modules: {
  }
})
