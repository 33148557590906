<template>
		<!-- Page Header Section Start Here -->
        <section class="page-header bg_img padding-tb">
            <div class="overlay"></div>
            <div class="container">
                <div class="page-header-content-area">
                    <h4 class="ph-title">Cart</h4>
                    <ul class="lab-ul">
                        <li><a href="/">Home</a></li>
                        <li><a class="active">Cart</a></li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- Page Header Section Ending Here -->
		
        <!-- Shop Cart Page Section start here -->		            
	    <div class="shop-cart padding-tb pb-0">
            <div class="container">
                <div class="section-wrapper">
                    <div class="cart-top">
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="product-item">
                                        <div class="p-thumb">
                                            <a href="#"><img src="../assets/colien/images/product/01.jpg" alt="product"></a>
                                        </div>
                                        <div class="p-content">
                                            <a href="#">Product Text Here</a>
                                        </div>
                                    </td>
                                    <td>$250</td>
                                    <td>
                                        <div class="cart-plus-minus">
                                            <div class="dec qtybutton">-</div>
                                            <input class="cart-plus-minus-box" type="text" name="qtybutton" value="3">
                                            <div class="inc qtybutton">+</div>
                                        </div>
                                    </td>
                                    <td>$750</td>
                                    <td>
                                        <a href="#"><img src="../assets/colien/images/del.png" alt="product"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="product-item">
                                        <div class="p-thumb">
                                            <a href="#"><img src="../assets/colien/images/product/02.jpg" alt="product"></a>
                                        </div>
                                        <div class="p-content">
                                            <a href="#">Product Text Here</a>
                                        </div>
                                    </td>
                                    <td>$250</td>
                                    <td>
                                        <div class="cart-plus-minus">
                                            <div class="dec qtybutton">-</div>
                                            <input class="cart-plus-minus-box" type="text" name="qtybutton" value="2">
                                            <div class="inc qtybutton">+</div>
                                        </div>
                                    </td>
                                    <td>$500</td>
                                    <td>
                                        <a href="#"><img src="../assets/colien/images/del.png" alt="product"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="product-item">
                                        <div class="p-thumb">
                                            <a href="#"><img src="../assets/colien/images/product/03.jpg" alt="product"></a>
                                        </div>
                                        <div class="p-content">
                                            <a href="#">Product Text Here</a>
                                        </div>
                                    </td>
                                    <td>$50</td>
                                    <td>
                                        <div class="cart-plus-minus">
                                            <div class="dec qtybutton">-</div>
                                            <input class="cart-plus-minus-box" type="text" name="qtybutton" value="2">
                                            <div class="inc qtybutton">+</div>
                                        </div>
                                    </td>
                                    <td>$100</td>
                                    <td>
                                        <a href="#"><img src="../assets/colien/images/del.png" alt="product"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="product-item">
                                        <div class="p-thumb">
                                            <a href="#"><img src="../assets/colien/images/product/04.jpg" alt="product"></a>
                                        </div>
                                        <div class="p-content">
                                            <a href="#">Product Text Here</a>
                                        </div>
                                    </td>
                                    <td>$100</td>
                                    <td>
                                        <div class="cart-plus-minus">
                                            <div class="dec qtybutton">-</div>
                                            <input class="cart-plus-minus-box" type="text" name="qtybutton" value="2">
                                            <div class="inc qtybutton">+</div>
                                        </div>
                                    </td>
                                    <td>$200</td>
                                    <td>
                                        <a href="#"><img src="../assets/colien/images/del.png" alt="product"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="product-item">
                                        <div class="p-thumb">
                                            <a href="#"><img src="../assets/colien/images/product/05.jpg" alt="product"></a>
                                        </div>
                                        <div class="p-content">
                                            <a href="#">Product Text Here</a>
                                        </div>
                                    </td>
                                    <td>$200</td>
                                    <td>
                                        <div class="cart-plus-minus">
                                            <div class="dec qtybutton">-</div>
                                            <input class="cart-plus-minus-box" type="text" name="qtybutton" value="2">
                                            <div class="inc qtybutton">+</div>
                                        </div>
                                    </td>
                                    <td>$400</td>
                                    <td>
                                        <a href="#"><img src="../assets/colien/images/del.png" alt="product"></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="cart-bottom">
                        <div class="cart-checkout-box">
                            <form action="https://labartisan.net/" class="coupon">
                                <input type="text" name="coupon" placeholder="Coupon Code..." class="cart-page-input-text">
                                <input type="submit" value="Apply Coupon">
                            </form>
                            <form action="https://labartisan.net/" class="cart-checkout">
                                <input type="submit" value="Update Cart">
                                <input type="submit" value="Proceed to Checkout">
                            </form>
                        </div>
                        <div class="shiping-box">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="calculate-shiping">
                                        <h4>Calculate Shipping</h4>
                                        <div class="outline-select">
                                            <select>
                                                <option value="volvo">United Kingdom (UK)</option>
                                                <option value="saab">Bangladesh</option>
                                                <option value="saab">Pakisthan</option>
                                                <option value="saab">India</option>
                                                <option value="saab">Nepal</option>
                                            </select>
                                            <span class="select-icon"><i class="icofont-caret-down"></i></span>
                                        </div>
                                        <div class="outline-select shipping-select">
                                            <select>
                                                <option value="volvo">State/Country</option>
                                                <option value="saab">Dhaka</option>
                                                <option value="saab">Benkok</option>
                                                <option value="saab">Kolkata</option>
                                                <option value="saab">Kapasia</option>
                                            </select>
                                            <span class="select-icon"><i class="icofont-caret-down"></i></span>
                                        </div>
                                        <input type="text" name="coupon" placeholder="Postcode/ZIP" class="cart-page-input-text"/>	
                                        <button type="submit" class="lab-btn"><span>Update Total</span></button>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12">
                                    <div class="cart-overview">
                                        <h4>Cart Totals</h4>
                                        <ul>
                                            <li>
                                                <span class="pull-left">Cart Subtotal</span>
                                                <p class="pull-right">$ 0.00</p>
                                            </li>
                                            <li>
                                                <span class="pull-left">Shipping and Handling</span>
                                                <p class="pull-right">Free Shipping</p>
                                            </li>
                                            <li>
                                                <span class="pull-left">Order Total</span>
                                                <p class="pull-right">$ 2940.00</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Shop Cart Page Section ending here -->
    <div class="section">
        <div class="page-cart">
            <div class="columns is-multiline">
                <div class="column is-12">
                    <h1 class="title">Cart</h1>
                </div>

                <div class="column is-12 box">
                    <table class="table is-fullwidth" v-if="cartTotalLength">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <CartItem
                                v-for="item in cart.items"
                                v-bind:key="item.product.id"
                                v-bind:initialItem="item"
                                v-on:removeFromCart="removeFromCart" />
                        </tbody>
                    </table>

                    <p v-else>You don't have any products in your cart...</p>
                </div>

                <div class="column is-12 box">
                    <h2 class="subtitle">Summary</h2>

                    <strong>${{ cartTotalPrice.toFixed(2) }}</strong>, {{ cartTotalLength }} items

                    <hr>

                    <router-link to="/cart/checkout" class="button is-dark">Proceed to checkout</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CartItem from '@/components/CartItem.vue'

export default {
    name: 'Cart',
    components: {
        CartItem
    },
    data() {
        return {
            cart: {
                items: []
            }
        }
    },
    mounted() {
    	document.title = 'Cart'
        this.cart = this.$store.state.cart
    },
    methods: {
        removeFromCart(item) {
            this.cart.items = this.cart.items.filter(i => i.product.id !== item.product.id)
        }
    },
    computed: {
        cartTotalLength() {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
        cartTotalPrice() {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.product.price * curVal.quantity
            }, 0)
        },
    }
}
</script>