<template>
<div class="inner-banner inner-bg13">
<div class="container">
<div class="inner-title">
<h3>Create Collection</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Pages</li>
<li>Create Collection</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="collection-widget-area pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-3">
<div class="author-profile-sidebar mr-20">
<div class="author-user">
<template v-if="$store.state.user.photo">
<img :src="$store.state.user.base64_photo" alt="Images">
</template>
<template v-else>
<img src="../assets/dab/images/collections/collection-profile2.jpg" alt="Images">
</template>
<i class="ri-check-line"></i>
</div>
<h3><a href="/author-profile/">{{$store.state.user.full_name}}</a></h3>
<span>@{{$store.state.user.username}}</span>
 <p>Earn income by posting your works</p>
<div class="sp-title">0x76669f...a0e9ca52 <i class="ri-folders-line"></i></div>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2941</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="col-lg-9">
<div class="collection-form-area">
<div class="section-title">
<template v-if="item">
<h2 v-if="tokenUri">Collectible Item<button type="submit" v-on:click="reset" class="default-btn border-radius-5" style="margin-left:20px;">Create New Item</button></h2>
<h2 v-else>Update Collectible Item<button type="submit" v-on:click="reset" class="default-btn border-radius-5" style="margin-left:20px;">Create New Item</button></h2>
</template>
<template v-else>
<h2>Create Collectible Item</h2>
</template>
</div>
<div class="collection-form">
  <div class="row">
  	<div class="col-lg-12">
  	
    <div v-if="currentFile" class="progress">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{ width: progress + '%' }"
      >
        {{ progress }}%
      </div>
    </div>
    <div class="form-group">
		<label>Upload file</label>
       	<input class="form-control" type="file" ref="file" @change="selectFile( $event )" />
    </div>
    <div class="form-group" v-show="showImagePreview||showVideoPreview">
		<label>Preview</label>
		<div class="preview-box">
		    <span class="previewButton-file-name">
				<img v-bind:src="imagePreview" v-show="showImagePreview" style="width:60%;"/>
				<video id="video-preview" controls v-show="showVideoPreview"/>
			</span>
		</div>
	</div>
	<div class="form-group cover_image" v-show="showCoverImage" style="margin-top:10px;">
		<label>Upload Cover</label>
	    <input class="form-control" type="file" ref="image" @change="selectImage( $event )" />
	</div>
	<div class="form-group" v-show="showCoverImagePreview">
		<label>Cover Preview</label>
		<img v-bind:src="coverImagePreview"/>
	</div>
    <div class="alert alert-light" role="alert">{{ message }}</div>
    </div>
  	</div>
<div class="row">
<div class="col-lg-12">
<div class="form-group">
<label>Item Name</label>
<input v-if="item" type="text" name="name" id="name" class="form-control" ref="title" :value="item.title" placeholder="e. g. 'walking in the air'">
<input v-else type="text" name="name" id="name" class="form-control" ref="title" placeholder="e. g. 'walking in the air'">
</div>
</div>
<div class="col-lg-12 col-md-12">
<div class="form-group">
<label>Description</label>
<textarea v-if="item" name="description" class="form-control" id="description" ref="description" :value="item.description" cols="30" rows="5" placeholder="e. g. 'after purchasing you will able to get the real product'"></textarea>
<textarea v-else name="description" class="form-control" id="description" ref="description" cols="30" rows="5" placeholder="e. g. 'after purchasing you will able to get the real product'"></textarea>
</div>
</div>
</div>
<div class="row">
<div class="col-lg-4">
<div class="form-group">
<label>Royalties</label>
<input ref="royalties" type="text" class="form-control" placeholder="5%">
</div>
</div>
</div>
<div class="row">
	<div class="col-lg-12 col-md-12">
		<div class="form-group">
			<label>Properties (Optional)</label>
		</div>
	</div>
</div>
<div class="row" v-for="{key,value} in inputs" :key="key">
<div class="col-lg-4 col-md-4">
<div class="form-group">
<input name="property_keys" :tabindex="key.counter+5" :data-counter="key.counter" :id="key.id" type="text" class="form-control" placeholder="e.g. Size" @change="addProperty($event);">
</div>
</div>
<div class="col-lg-4 col-md-4">
<div class="form-group">
<input name="property_values" :tabindex="value.counter+6" :data-counter="value.counter" :id="value.id" type="text" class="form-control" placeholder="e.g. M" @change="addProperty($event);">
</div>
</div>
</div>
<div class="row">
<div class="col-lg-12">
<div class="form-group">
<label>Number Of Copies</label>
<input tabindex="1001" v-if="item" type="text" class="form-control" placeholder="e. g. '1'" ref="quantity" :value="item.quantity">
<input tabindex="1001" v-else type="text" class="form-control" placeholder="e. g. '1'" ref="quantity">
</div>
</div>
<div class="col-lg-12">
<div class="checkbox-method-area">
<div class="col-lg-12 col-md-12">
<div class="checkbox-method">
<p>
<input type="radio" tabindex="1002" id="fixed-price" checked="checked" name="price-radio-group" value="fixed-price" v-model="checkedPriceType">
<label for="fixed-price">Fixed Price</label>
</p>
</div>
</div>
<div class="col-lg-12 col-md-12">
<div class="checkbox-method">
<p>
<input type="radio" tabindex="1003" id="timed-auction" name="price-radio-group" value="timed-auction" v-model="checkedPriceType">
<label for="timed-auction">Timed Auction</label>
</p>
</div>
</div>
<div class="col-lg-12 col-md-12">
<div class="checkbox-method">
<p>
<input type="radio" tabindex="1004" id="open-bid" name="price-radio-group" value="open-bid" v-model="checkedPriceType">
<label for="open-bid">Open For Bid</label>
</p>
</div>
</div>
<div class="row">
	<div class="col-lg-6" v-if="checkedPriceType=='timed-auction'">
		<label>Starting Date</label>
		<datetime format="YYYY-MM-DD H:i:s" width="300px" name="startDate" ref="startDate"></datetime>
	</div>
	<div class="col-lg-6" v-if="checkedPriceType=='timed-auction'">
		<label>End Date</label>
		<datetime format="YYYY-MM-DD H:i:s" width="300px" name="endDate" ref="endDate"></datetime>
	</div>
</div>
<div class="col-lg-4" v-if="checkedPriceType=='fixed-price'">
<div class="form-group">
<label>Price</label>
<input v-if="item" tabindex="1009" type="text" class="form-control" ref="price" :value="item.price" placeholder="e. g. '100'">
<input v-else type="text" tabindex="1009" class="form-control" ref="price" placeholder="e. g. '100'">
</div>
</div>
<div class="col-lg-4" v-if="checkedPriceType=='fixed-price'">
<div class="form-group select-group">
<label>Currency</label>
<select class="form-select form-control">
	<template v-for="curr in currencies">
	<option v-if="item" :value="curr.id" :selected="curr.id == item.currency">{{curr.name}}</option>
	<option v-else :value="curr.id" :selected="curr.id == 1">{{curr.name}}</option>
	</template>
</select>
</div>
</div>
</div>
</div>
<div class="col-lg-12 col-md-12">
	<div class="row">
		<div class="col-lg-2 col-md-2">
			<button type="button" v-on:click="processNFT" :disabled="tokenUri" class="default-btn border-radius-5" data-bs-toggle="modal" data-bs-target="#exampleModal">
				Create Item
			</button>
		</div>
	</div>
</div>
</div>
<div>

	<!-- Modal -->
	<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	  <div class="modal-dialog">
	    <div class="modal-content">
	      <div class="modal-body">
	      	<h2>Follow steps</h2>
	        <ul>
	        	<li :class="steps[0]">
	        		<h3>Upload</h3>
	        		<p>Uploading of all media assets and metadata to Server</p>
	        	</li>
	            <li :class="steps[1]">
	            	<h3>Mint</h3>
	            	<p>Send transaction to create your NFT</p>
	            </li>
	            <li :class="steps[2]">
	            	<h3>Approve</h3>
	            	<p>This transaction is conducted only once per collection</p>	
	            </li>
	            <li :class="steps[3]">
	            	<h3>Put on sale</h3>
	            	<p>Sign message to set fixed price</p>	
	            </li>
	       </ul>
	      </div>
	      
	      <div class="modal-footer">
	        <button type="button" :disabled="!is_cancel" class="default-btn border-radius-5" data-bs-dismiss="modal">Cancel</button>
	        <button type="button" :disabled="!is_ok" v-on:click="viewNFT"  class="default-btn border-radius-5" data-bs-dismiss="modal">Ok</button>
	      </div>
	      
	    </div>
	    
	  </div>
	</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
<toast :show="toast.show" :text="toast.text" @hide-toast="hideToast"></toast>    
 <vue-metamask userMessage="msg" @onComplete="onComplete"></vue-metamask> 
</template>
<script>
import UploadService from "../services/UploadFilesService";
import SingleImagePreview from '@/components/SingleImagePreview.vue';
import axios from 'axios';
import Toast from '@/components/Toast.vue';
import datetime from 'vuejs-datetimepicker';
import VueMetamask from 'vue-metamask';

export default {
    name: 'CreateCollection',
    components: {
	    //UploadFiles,
	    VueMetamask,
	    SingleImagePreview,
	    Toast,
	    datetime,
	},
	data() {
	    return {
	      is_ok: false,
	      is_cancel:true,
	      counter: 0,
	      item: {},
	      tokenUri:{},
	      currencies:["USD","ETH"],
	      checkedPriceType: 'fixed-price',
	      price:0,
	      metamaskWallet:{},
	      itemUrl:"",
	      showModal: false,
	      steps:['loader','uncheck','uncheck','uncheck'],
	      startDate:"",
	      endDate:"",
	      properties:[],
	      selectedFiles: undefined,
	      selectedImages: undefined,
	      currentFile: undefined,
	      currentImage: undefined,
	      progress: 0,
	      message: "",
		  tokenUri:{},
	      fileInfos: [],
	      showImagePreview: false,
	      showCoverImage:false,
	      showVideoPreview: false,
		  imagePreview: '',
		  file:'',
		  image:'',
		  coverImagePreview:'',
		  showCoverImagePreview:false,
		  inputs: [{key:{
		  				counter:0,
			      		id: 'key0',
			      		label: 'e.g. M',
			      		value:'',
		      		},
			      	value:{
			      		counter:0,
			      		id: 'value0',
			      		label: 'e.g. M',
			      		value:'',
		      		}
		  },
		  ],
	    }
	},
    mounted() {
        document.title = 'Create Collection';
        this.item = JSON.parse(localStorage.getItem("item"));
        this.tokenUri=JSON.parse(localStorage.getItem("tokenUri"));
        this.metamaskWallet = localStorage.getItem("metamaskWallet");
        if(this.tokenUri) {
        	this.checkedPriceType = this.tokenUri.sale_type;
        }
        console.log(this.tokenUri);
        if (this.item) {
        	this.itemUrl = "https://bitcrome.com/items/"+this.item.uuid+"/";
        }
        this.getCurrencies();
        
	    UploadService.getFiles("/api/itemfiles/").then(response => {
	      this.fileInfos = response.data.results;
	    });
    },
    computed: {
  		toast() {
  			return this.$store.getters.toast;
  		}
 	},
    
    methods: {
    	addProperty(event) {
    		console.log(event.target.dataset.counter)
    		console.log("addProperty");
    		var key = document.querySelector("input[id=key"+event.target.dataset.counter+"]").value
    		var value = document.querySelector("input[id=value"+event.target.dataset.counter+"]").value
			
			var property_keys = document.getElementsByName("property_keys")
			var property_values = document.getElementsByName("property_values")
			
			var json_arr=[];
			
			for (var i = 0; i < property_keys.length; i++) {
				var json = {};
                var k = property_keys[i];
                var v = property_values[i];
                if(k.value && v.value) {
                	json[k.value]=v.value
                	json_arr.push(JSON.stringify(json))
                }
            }
            this.properties=json_arr;
				
    		if (key && value){
	    		this.counter +=1;
	    		this.inputs.push(
			    {key:{
		    		counter: this.counter,
			      		id: 'key'+this.counter,
			      		label: 'e.g. M',
			      		value:'',
			      		focus:true
		      		},
			      	value:{
			      		counter:this.counter,
			      		id: 'value'+this.counter,
			      		label: 'e.g. M',
			      		value:'',
			      		focus:true
		      		}
				});
	    	}
    	},
    	selectFile( event ) {
	      this.selectedFiles = this.$refs.file.files;
	      /*
					Set the local file variable to what the user has selected.
				*/
				this.file = event.target.files[0];

				/*
					Initialize a File Reader object
				*/
				let reader  = new FileReader();
				
				/*
					Check to see if the file is not empty.
				*/
				if( this.file ){
					/*
						Ensure the file is an image file.
					*/
					console.log(this.file.type);
					console.log(this.file.type.indexOf('video/') !== -1)
					if ( /\.(jpe?g|png|gif)$/i.test( this.file.name ) ) {
						
						/*
							Fire the readAsDataURL method which will read the file in and
							upon completion fire a 'load' event which we will listen to and
							display the image in the preview.
						*/
						reader.readAsDataURL( this.file );
						/*
							Add an event listener to the reader that when the file
							has been loaded, we flag the show preview as true and set the
							image to be what was read from the reader.
						*/
						reader.addEventListener("load", function () {
							this.showImagePreview = true;
							this.showCoverImage = false;
							this.showVideoPreview = false;
							this.showCoverImagePreview = false;
							this.imagePreview = reader.result;
						}.bind(this), false);
								
					}else if (this.file.type.indexOf('video/') !== -1) {
						let video = document.getElementById('video-preview');
						let reader = new FileReader();
		
						reader.readAsDataURL( this.file );
						reader.addEventListener('load', function(){
							this.showImagePreview = false;
							this.showVideoPreview = true;
							this.showCoverImage=true;
							video.src = reader.result;
						}.bind(this), false);
					} else {
						this.showImagePreview = false;
						this.showVideoPreview = false;
						this.showCoverImage=true;
					}
				}
    },
    selectImage( event ) {
      this.selectedImages = this.$refs.image.files;
      /*
					Set the local file variable to what the user has selected.
				*/
				this.image = event.target.files[0];
				/*
					Initialize a File Reader object
				*/
				let reader  = new FileReader();
				
				/*
					Check to see if the file is not empty.
				*/
				if( this.image ){
					/*
						Ensure the file is an image file.
					*/
					
					if ( /\.(jpe?g|png|gif)$/i.test( this.image.name ) ) {
						
						/*
							Fire the readAsDataURL method which will read the file in and
							upon completion fire a 'load' event which we will listen to and
							display the image in the preview.
						*/
						reader.readAsDataURL( this.image );
						/*
							Add an event listener to the reader that when the file
							has been loaded, we flag the show preview as true and set the
							image to be what was read from the reader.
						*/
						reader.addEventListener("load", function () {
							this.showCoverImagePreview = true;
							this.coverImagePreview = reader.result;
						}.bind(this), false);
								
					}
				}
    },
    viewNFT: function () {
    	const item = JSON.parse(localStorage.getItem("item"));
    	const toPath = this.$route.query.to || '/items/'+item.uuid+"/";
    	localStorage.removeItem("item");
    	localStorage.removeItem("tokenUri");
		this.$router.push(toPath);
    },
    deleteItemFile: function (itemfile_id) {
      const token = localStorage.getItem("token")
        axios.defaults.headers.common["Authorization"] = "Token " + token
	    
	    axios.delete("/api/itemfiles/"+itemfile_id+"/", {})
                .then(response => {
				    console.log(response.data);
				    this.message = "Delete the file successfully!";
				    this.$store.commit('showToast', "Delete the file successfully!");
				    return UploadService.getFiles("/api/itemfiles/");
                })
                .then(files => {
		          this.fileInfos = files.data.results;
		        })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
    	})
    },
    async upload() {
      this.steps=['loader','uncheck','uncheck','uncheck']
      this.progress = 0;
	  const item = JSON.parse(localStorage.getItem("item"));
	  const user = JSON.parse(localStorage.getItem("user"));
	  if (!item) {
	  	//console.log(this.checkedPriceType)
	  	
	  	if (this.checkedPriceType=='timed-auction') {
           this.price=0;
        } else if (this.checkedPriceType=='fixed-price') {
           this.price = this.$refs.price.value;
        } else {
           this.price=0;
        }
	 	const itemFormData = {
	 		title: this.$refs.title.value||"undefined",
			meta_description: this.$refs.description.value||"undefined",
			meta_keywords: this.$refs.description.value||"undefined",
			description: this.$refs.description.value||"undefined",
			content: this.$refs.description.value||"undefined",
			quantity: this.$refs.quantity.value||0,
			price: this.price||0,
			active:1,
			user:user.id,
			currency:1,
	    }
	    console.log(itemFormData)
	    
	    await axios
                .post("/api/items/", itemFormData)
                .then(response => {
                	console.log(response.data);
                	console.log(response.data.uuid);
                	this.item=JSON.stringify(response.data);
                	this.itemUrl = "https://bitcrome.com/items/"+response.data.uuid+"/";
					localStorage.setItem("item", JSON.stringify(response.data));
					
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
    	})
	  } 
	  
	  if(this.selectedFiles) {
      this.currentFile = this.selectedFiles.item(0);
      UploadService.upload("/api/itemfiles/",this.currentFile, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then(response => {
          this.message = response.data.message;
          //this.message = "Upload the file successfully!";
          //this.$store.commit('showToast', "Upload the file successfully!");
          return UploadService.getFiles("/api/itemfiles/");
        })
        .then(files => {
          this.fileInfos = files.data.results;
        })
        .catch(() => {
          this.progress = 0;
          this.message = "Could not upload the file!";
          this.$store.commit('showToast', "Could not upload the file!");
          this.currentFile = undefined;
        });
	
      this.selectedFiles = undefined;
      }
      
	  if(this.selectedImages) {
	 	this.currentImage = this.selectedImages.item(0);
      UploadService.upload("/api/itemimages/",this.currentImage, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then(response => {
          this.message = response.data.message;
          this.message = "Upload the image successfully!";
          //this.$store.commit('showToast', "Upload the image successfully!");
          return UploadService.getFiles("/api/itemimages/");
        })
        .then(files => {
          //this.fileInfos = files.data.results;
        })
        .catch(() => {
          this.progress = 0;
          this.message = "Could not upload the file!";
          this.$store.commit('showToast', "Could not upload the file!");
          this.currentImage = undefined;
        });
	
      }
      
      return this.mint()
      
    },
    	onComplete(data){
	    	console.log('data:', data);
	    	console.log(data['metaMaskAddress']);
	    	if(!data['metaMaskAddress']) {
	    		alert("Please download Metamask to mint NFT. Link: https://metamask.io/download.html");
	    	}
	    	this.metamaskWallet = data
	    	//this.$store.commit('showToast','setMetamaskWallet', data);
	    },
    	hideToast() {
  			this.$store.commit("hideToast");
  		},
  		
  		async processNFT() {
  			this.is_cancel=true
  			this.is_ok=false
  			this.upload()
  		},
  		async approve() {
  			this.steps=['check','check','loader','uncheck']
  			return this.putOnSale()
  		},
  		async putOnSale() {
  			this.steps=['check','check','check','loader']
  			return this.done()
  		},
  		async done() {
  			this.is_cancel=false
  			this.is_ok=true
  			this.steps=['check','check','check','check']
  		},
  		async mintNFT(tokenURIDataUrl) {
  			this.steps=['check','loader','uncheck','uncheck']
			const API_URL = "https://eth-ropsten.alchemyapi.io/v2/L6DL1hRnnw_P9FeVojb-qaOxfJUbmiLU"
			const PUBLIC_KEY = "0x28F90bA1353b21Ca3fac3Ab665b1DBDF8E3FB539"
			const PRIVATE_KEY = "f9ef743b5b510b8cddf6fdbfcdc57c07457ddf028b67e00c6a4e0f90ce444e79"
			
			const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
			const web3 = createAlchemyWeb3(API_URL)
			
			const contract = require("../../artifacts/contracts/MyNFT.sol/MyNFT.json")
			console.log(JSON.stringify(contract.abi))
			const contractAddress = "0xd0F6413e284Ee26D5dDCd9F3A2e1b90A0E6215EA"
			const nftContract = new web3.eth.Contract(contract.abi, contractAddress)
			const nonce = await web3.eth.getTransactionCount(PUBLIC_KEY, "latest") //get latest nonce
			//the transaction
			const tx = {
			    from: PUBLIC_KEY,
			    to: contractAddress,
			    nonce: nonce,
			    gas: 500000,
			    data: nftContract.methods.mintNFT(PUBLIC_KEY, tokenURIDataUrl).encodeABI(),
			}
			
			const signPromise = web3.eth.accounts.signTransaction(tx, PRIVATE_KEY)
			signPromise
			    .then((signedTx) => {
			      web3.eth.sendSignedTransaction(
			        signedTx.rawTransaction,
			        function (err, hash) {
			          if (!err) {
			            this.message = "The hash of your transaction is: "+hash;
			            const tokenUri=JSON.parse(localStorage.getItem("tokenUri")); 

			            const formData = {
			            	txn_hash:hash,
			            	id:tokenUri.id,
		            	}
			            axios.put("/api/tokenuris/"+tokenUri.id+"/", formData)
			                .then(response => {
			                	console.log(response.data);
			                    const toPath = this.$route.query.to || '/create-collection/';
			                    //this.$router.push(toPath);
			                    this.message = "Update the transaction hash successfully!";
			                })
			                .catch(error => {
			                    if (error.response) {
			                        for (const property in error.response.data) {
			                        	this.message = "Error the transaction hash error!";
			                        }
			                    } else {
			                        this.message = "Something went wrong. Please try again";
			                        console.log(JSON.stringify(error))
			                    }
			                })
			                
			            
			            
			            console.log(
			              "The hash of your transaction is: ",
			              hash,
			              "\nCheck Alchemy's Mempool to view the status of your transaction!"
			            )
			            
			          } else {
			            console.log(
			              "Something went wrong when submitting your transaction:",
			              err
			            )
			          }
			        }
			      )
			    })
			    .catch((err) => {
			      console.log(err)
			    })
			    
			return this.approve()
		},
		
    	async mint(){
    		console.log(this.checkedPriceType)
    		this.steps=['check','loader','uncheck','uncheck']
    		
    		const token = localStorage.getItem("token")
            const item = JSON.parse(localStorage.getItem("item"))
            axios.defaults.headers.common["Authorization"] = "Token " + token
    		//create token uri 
    		const startDate=null
    		const endDate=null
	        if (this.checkedPriceType=='timed-auction') {
    			const startDate = document.querySelector("input[name=startDate]").value
    			const endDate = document.querySelector("input[name=endDate]").value
    		} 
    		const tokenUriFormData = {
    			name: item.title,
    			address:this.metamaskWallet['metaMaskAddress'],
    			description: item.description,
    			assetURL: this.itemUrl,
    			traits:this.properties,
    			item_uuid: item.uuid,
    			sale_type: this.checkedPriceType,
    			start_date: startDate,
    			end_date: endDate,
    			royalties: this.$refs.royalties.value||0,
    			properties:this.properties,
	       	}
			console.log(tokenUriFormData);	
			
	        await axios
	                .post("/api/tokenuris/", tokenUriFormData)
	                .then(response => {
	                	console.log(response.data);
	                	this.message = "Create token uri successfully!";
	                	//this.$store.commit('showToast', "Create token uri successfully!");
	                    const toPath = this.$route.query.to || '/';
	                    //this.$router.push(toPath);
	                    this.tokenUri=JSON.stringify(response.data)
	                    localStorage.setItem("tokenUri", JSON.stringify(response.data));
	                    return this.mintNFT("https://bitcrome.com/api/tokenuris/"+this.tokenUri.id+"/?format=json");
	                    
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	        })
	                
    	},
  		
    	reset: function () {
    		localStorage.removeItem("item");
    		localStorage.removeItem("tokenUri");
    		const toPath = this.$route.query.to || '/create-collection/';
			this.$router.push(toPath);
		
    	},
    	async getCurrencies() {
    		await axios
                .get("/api/currencies/?page_size=1000")
                .then(response => {
					this.currencies = response.data.results
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	async submitCreateItemForm() {
            const token = localStorage.getItem("token")
            const item = JSON.parse(localStorage.getItem("item"))
            axios.defaults.headers.common["Authorization"] = "Token " + token
            if(item) {
            	this.itemUrl = "https://bitcrome.com/items/"+item.uuid+"/";
            	
            	if (this.checkedPriceType=='timed-auction') {
            		this.price=0;
            	} else if (this.checkedPriceType=='fixed-price') {
            		this.price = this.$refs.price.value;
            	} else {
            		this.price=0;
            	}
            	
	            const formData = {
		            	title: this.$refs.title.value,
					    meta_description: this.$refs.description.value,
					    meta_keywords: this.$refs.description.value,
					    description: this.$refs.description.value,
					    content: this.$refs.description.value,
					    quantity: this.$refs.quantity.value||0,
					    price: this.price,
					    active:1,
					    currency:1,
					    id:item.id,
					    uuid:item.uuid,
	            }
	            await axios
	                .put("/api/items/"+item.uuid+"/", formData)
                .then(response => {
                	console.log(response.data);
                	localStorage.setItem("item", JSON.stringify(response.data));
                    const toPath = this.$route.query.to || '/create-collection/';
                    //this.$router.push(toPath);
                    this.message = "Update the item successfully!";
                	//this.$store.commit('showToast', "Update the file successfully!");
                    this.mint()
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
                })
            } else {
            	if (this.checkedPriceType=='timed-auction') {
            		this.price=0;
            	} else if (this.checkedPriceType=='fixed-price') {
            		this.price = this.$refs.price.value;
            	} else {
            		this.price=0;
            	}
            	const formData = {
	            	title: this.$refs.title.value,
					meta_description: this.$refs.description.value,
					meta_keywords: this.$refs.description.value,
					description: this.$refs.description.value,
					content: this.$refs.description.value,
					quantity: this.$refs.quantity.value||0,
					price: this.price,
					active:1,
					currency:1,
	            }
				
	            await axios
	                .post("/api/items/", formData)
	                .then(response => {
	                    localStorage.setItem("item", JSON.stringify(response.data));
	                    this.itemUrl = "https://bitcrome.com/items/"+response.data.uuid+"/";
	                	console.log(response.data);
	                	this.message = "Create the item successfully!";
	                	//this.$store.commit('showToast', "Create the file successfully!");
	                    //const toPath = this.$route.query.to || '/create-collection/';
	                    //this.$router.push(toPath);
	                    return this.mint()
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
            }
        }
    }
}
</script>
<style>
.modal-body ul li {
	list-style-image: url("../assets/dab/images/uncheck.svg");
}
.modal-body	ul li.loader {
	list-style-image: url("../assets/dab/images/loader.svg");
}
.modal-body	ul li.check {
	list-style-image: url("../assets/dab/images/check.svg");
}
.modal-body	ul li.uncheck {
	list-style-image: url("../assets/dab/images/uncheck.svg");
}
.modal-content {
	background-color: white !important;
	opacity: 1;
}
</style>