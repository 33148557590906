<template>
		<!-- Page Header Section Start Here -->
		<section class="page-header bg_img padding-tb">
			<div class="overlay"></div>
		    <div class="container">
		    	<div class="page-header-content-area">
		            <h4 class="ph-title">{{page.title}}</h4>
		            <ul class="lab-ul">
		            	<li><router-link to="/">Home</router-link></li>
		                <li><a class="active">{{ page.title }}</a></li>
		        	</ul>
		    	</div>
		    </div>
		</section>
		<!-- Page Header Section Ending Here -->
		<!-- about section start here -->
		<section class="about-section about-page relative">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-12">
						<div class="about-wrapper">
							<div class="about-title">
								<h3>{{page.title}}</h3>
								<h6><span>{{ page.description }}</span></h6>
							</div>
							<div class="about-content" v-html="page.content"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
        <!-- about section ending here -->
</template>

<script>
import axios from 'axios'

export default {
    name: 'Page',
    components: {
    },
    data() {
        return {
            page: {}
        }
    },
    mounted() {
        this.getPage()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Page') {
                this.getPage()
            }
        }
    },
    methods: {
        async getPage() {
            const pageSlug = this.$route.params.page_slug

            this.$store.commit('setIsLoading', true)

            axios
                .get(`/api/v1/pages/${pageSlug}/`)
                .then(response => {
                    this.page = response.data
                    document.title = this.page.title
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>