<template>
		<!-- Page Header Section Start Here -->
        <section class="page-header bg_img padding-tb">
            <div class="overlay"></div>
            <div class="container">
                <div class="page-header-content-area">
                    <h4 class="ph-title">Products</h4>
                    <ul class="lab-ul">
                        <li><a href="/">Home</a></li>
                        <li><a class="active">Products</a></li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- Page Header Section Ending Here -->
		
        <!-- shop page Section Start Here -->
        <div class="shop-page padding-tb bg-ash">
            <div class="container">
                <div class="section-wrapper">
                    <div class="row justify-content-center">
                        <div class="col-lg-9 col-12">
                            <article>
                                <div class="shop-title d-flex flex-wrap justify-content-between">
                                    <p style="display:none;">Showing 01 - 12 of 139 Results</p>
                                    <div class="product-view-mode">
                                        <a class="active" data-target="grids"><i class="icofont-ghost"></i></a>
                                    </div>
                                </div>

                                <div class="shop-product-wrap grids row justify-content-center">
                                    
                                    <div class="col-lg-4 col-md-6 col-12" v-for="item in items">
                                        <div class="product-item">
                                            <div class="product-thumb">
                                                <img src="../assets/colien/images/product/01.jpg" alt="shope">
                                                <div class="product-action-link">
                                                    <a href="../assets/colien/images/product/01.jpg" data-rel="lightcase"><i class="icofont-eye"></i></a>
                                                    <a href="#"><i class="icofont-heart-alt"></i></a>
                                                    <a href="#"><i class="icofont-cart-alt"></i></a>
                                                </div>
                                            </div>
                                            <div class="product-content">
                                                <h6><a :href="'/items/'+item.slug+'/'">{{item.title}}</a></h6>
                                                <h6>{{item.price}}</h6>
                                            </div>
                                        </div>
                                        <div class="product-list-item">
                                            <div class="product-thumb">
                                                <img src="../assets/colien/images/product/01.jpg" alt="shope">
                                                <div class="product-action-link">
                                                    <a href="../assets/colien/images/product/01.jpg" data-rel="lightcase"><i class="icofont-eye"></i></a>
                                                    <a href="#"><i class="icofont-heart-alt"></i></a>
                                                    <a href="#"><i class="icofont-cart-alt"></i></a>
                                                </div>
                                            </div>
                                            <div class="product-content">
                                                <h6><a :href="'/items/'+item.slug+'/'">{{item.title}}</a></h6>
                                                <h6>
                                                    {{item.price}}
                                                </h6>
                                                <p>{{item.description}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="paginations" style="display:none;">
                                    <ul class="lab-ul d-flex flex-wrap justify-content-center">
                                        <li>
                                            <a href="#">1</a>
                                        </li>
                                        <li class="d-none d-sm-block">
                                            <a href="#">2</a>
                                        </li>
                                        <li class="d-none d-sm-block">
                                            <a href="#">3</a>
                                        </li>
                                        <li>
                                            <a class="dot">...</a>
                                        </li>
                                        <li class="d-none d-sm-block">
                                            <a href="#">9</a>
                                        </li>
                                        <li class="d-none d-sm-block">
                                            <a href="#">10</a>
                                        </li>
                                        <li>
                                            <a href="#">11</a>
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                        <div class="col-lg-3 col-md-7 col-12">
                            <aside>
                                <div class="widget widget-search">
                                    <div class="widget-header">
                                        <h5>Search keywords</h5>
                                    </div>
                                    <form action="/" class="search-wrapper">
                                        <input type="text" name="s" placeholder="Your Search...">
                                        <button type="submit"><i class="icofont-search-2"></i></button>
                                    </form>
                                </div>

                                <div class="widget widget-category">
                                    <div class="widget-header">
                                        <h5>All Categories</h5>
                                    </div>
                                    <div class="widget-wrapper">
                                        <ul class="lab-ul shop-menu">
                                            <li>
                                                <a href="#0">Apple</a>
                                                <ul class="lab-ul shop-submenu">
                                                    <li><a href="#">Apple 1</a></li>
                                                    <li><a href="#">Apple 2</a></li>
                                                    <li><a href="#">Apple 3</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#0">Orange</a>
                                                <ul class="lab-ul shop-submenu">
                                                    <li><a href="#">Orange 1</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#0">Tomato</a>
                                                <ul class="lab-ul shop-submenu">
                                                    <li><a href="#">Tomato 1</a></li>
                                                    <li><a href="#">Tomato 2</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#0">Honey</a>
                                                <ul class="lab-ul shop-submenu">
                                                    <li><a href="#">Honey 1</a></li>
                                                    <li><a href="#">Honey 2</a></li>
                                                    <li><a href="#">Honey 3</a></li>
                                                    <li><a href="#">Honey 4</a></li>
                                                    <li><a href="#">Honey 5</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#0">Banana</a>
                                                <ul class="lab-ul shop-submenu">
                                                    <li><a href="#">Banana 1</a></li>
                                                    <li><a href="#">Banana 2</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#0">Blueberry</a>
                                                <ul class="lab-ul shop-submenu">
                                                    <li><a href="#">Blueberry 1</a></li>
                                                    <li><a href="#">Blueberry 2</a></li>
                                                    <li><a href="#">Blueberry 3</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#0">Lettuce</a>
                                                <ul class="lab-ul shop-submenu">
                                                    <li><a href="#">Lettuce 1</a></li>
                                                    <li><a href="#">Lettuce 2</a></li>
                                                    <li><a href="#">Lettuce 3</a></li>
                                                    <li><a href="#">Lettuce 4</a></li>
                                                    <li><a href="#">Lettuce 5</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- shop page Section ENding Here -->
</template>

<script>
import axios from 'axios'

export default {
    name: 'Item',
    data() {
        return {
            items:[],
        }
    },
    mounted() {
    	document.title = 'Shop'
        this.getItemList() 
    },
    methods: {
    	async getItemList() {
  			await axios
                .get("/api/items/")
                .then(response => {
					this.items = response.data.results
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        
                        console.log(JSON.stringify(error))
                    }
    	})
  	},
    addToCart() {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }

            const item = {
                product: this.product,
                quantity: this.quantity
            }

            this.$store.commit('addToCart', item)
        }
    }
}
</script>