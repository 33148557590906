<template>
<div class="inner-banner inner-bg9">
<div class="container">
<div class="inner-title">
<h3>Our Top Authors</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Pages</li>
<li>Authors</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="author-widget-bg author-area-bg pt-100 pb-70">
<div class="container">
<div class="section-title">
<h2>Top Author</h2>
</div>
<div class="row pt-45 justify-content-center">
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img6.jpg" alt="Images">
</a>
<div class="content">
 <div class="author-user">
<img src="../assets/dab/images/author/author-user1.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Olivia Jenar</a></h3>
<span>@Jenar</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2941</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img7.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user2.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">James Parker</a></h3>
<span>@Parker</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2945</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img8.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user3.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Lucas Adison</a></h3>
<span>@Adison</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>4205</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img12.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user4.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Amelia Rosia</a></h3>
<span>@Rosia</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2306</h4>
</div>
 <div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img1.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user5.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Barb E. Dahl</a></h3>
<span>@Dahl</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2106</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
 <img src="../assets/dab/images/author/author-img2.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user6.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Biff Wellington</a></h3>
<span>@Biff</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2206</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img3.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user7.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Adam Zapel</a></h3>
<span>@Adam</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2706</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
 <i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img4.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user8.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Orson Carte</a></h3>
<span>@Orson</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2406</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img5.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user8.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Ray Gunn</a></h3>
<span>@Ray</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2106</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img9.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user9.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Thomas Jon</a></h3>
<span>@Thomas</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2106</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
 </div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img11.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user10.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Radil Hence</a></h3>
<span>@Hence</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2006</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="author-card box-shadow">
<a href="author-profile.html">
<img src="../assets/dab/images/author/author-img12.jpg" alt="Images">
</a>
<div class="content">
<div class="author-user">
<img src="../assets/dab/images/author/author-user11.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<h3><a href="author-profile.html">Anvi Jordan</a></h3>
<span>@Jordan</span>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2206</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
 </li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-12 col-md-12 text-center">
<div class="pagination-area">
<a href="authors.html" class="prev page-numbers">
<i class="ri-arrow-left-s-line"></i>
</a>
<span class="page-numbers current" aria-current="page">1</span>
<a href="authors.html" class="page-numbers">2</a>
<a href="authors.html" class="page-numbers">3</a>
<a href="authors.html" class="next page-numbers">
<i class="ri-arrow-right-s-line"></i>
</a>
</div>
</div>
</div>
</div>
</div>


<div class="involved-area-two pt-100 pb-70">
<div class="container">
<div class="section-title text-center">
<h2>Get Involved</h2>
</div>
<div class="row pt-45">
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-flight-takeoff-line"></i>
</div>
<h3>Join Our <b>Community</b></h3>
<ul class="social-link">
<li>
<a href="https://www.google.com/" target="_blank">
<i class="ri-google-fill"></i>
</a>
</li>
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
<li>
<a href="https://www.linkedin.com/" target="_blank">
<i class="ri-linkedin-fill"></i>
</a>
</li>
</ul>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-checkbox-circle-line"></i>
</div>
<h3>Become A <b>Creator</b></h3>
<a href="register.html" class="default-btn">Register</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-heart-2-line"></i>
</div>
<h3>Become A <b>Charity Partner</b></h3>
<a href="create-collection.html" class="default-btn">Express Interest</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-discuss-line"></i>
</div>
<h3>Reach Out To <b>Our Team</b></h3>
<a href="contact.html" class="default-btn">Contact Now</a>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
export default {
    name: 'AuthorProfile',
    mounted() {
        document.title = 'Author Profile'
    },
}
</script>